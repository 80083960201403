import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import liff from '@line/liff';
import LoadingOverlay from 'react-loading-overlay'

import Site from '../site';
import Chat from '../chat';

class Layout extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      line_user: null,
      line_liffid: null,
    }

  }


  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.line_liffid && !this.state.line_liffid) {
      console.log('Layout Guest', nextProps.line_liffid)

      const LIFF_ID = nextProps.line_liffid

      this.setState({ line_liffid: LIFF_ID }, async () => {
        liff.init({
          liffId: LIFF_ID,
          //redirectUrl: process.env.REACT_APP,
          //withLoginOnExternalBrowser: true,
        }).then(async () => {

          liff.ready.then(async () => {
            //console.log('Liff ID', liff.id, liff.getVersion(), liff.getAccessToken())

            //console.log('isInClient', liff.isInClient(), LIFF_ID)
            //console.log('isLoggedIn', liff.isLoggedIn(), LIFF_ID)

            if (liff.isLoggedIn()) {
              const line_profile = await liff.getProfile()
              console.log('line_profile', line_profile)

              this.setState({ line_userid: line_profile.userId }, async () => {

                //this.login({ line_userid: line_profile.userId })

                if (nextProps.submitLogin) {
                  nextProps.submitLogin({ line_userid: line_profile.userId })
                }
                else {
                  console.log('Not found', 'nextProps.submitLogin')
                }

              })

            }
            else {
              liff.login()
              console.log('Not login')
            }
          }).catch(error => {
            console.error(error)
          })

        }).catch((err) => {
          // Error happens during initialization
          console.log(err.code, err.message);
        });


      })
    }

    if (nextProps && typeof nextProps.loading !== 'undefined') {

      this.setState({ loading: nextProps.loading })

    }
  }


  render() {
    const { loading } = this.state
    const { web_title, web_description } = this.props

    return (
      <>

        {web_title &&
          <Helmet>
            <title>{web_title}</title>
            {web_description && <meta name="description" content={web_description} />}
          </Helmet>
        }

        <LoadingOverlay active={loading} spinner>

          {this.props.children}

        </LoadingOverlay>

        <Site />
        <Chat />


      </>
    );
  }
}




const mapStateToProps = state => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(Layout);