import React from 'react';
import ReactPlayer from 'react-player'

class Video extends React.Component {

  render() {

    return (
      <>
        {this.props.url &&
          <div className='player-wrapper'>
            <ReactPlayer
              className='react-player'
              url={this.props.url}
              width='100%'
              height='100%'
              controls={false}
              config={{
                vimeo: {
                  playerOptions: { controls: false, responsive: true }
                }
              }}
            />
          </div>
        }
      </>
    )
  }
}


export default Video