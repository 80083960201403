import React from 'react';
import { Image } from 'react-bootstrap';

class BankLogo extends React.Component {


  render() {

    const _bank = this.props.bank
    let icon = require('../assets/images/bank/bank.png')
    if (_bank) {
      try {
        icon = require('../assets/images/bank/' + _bank + '.png')
      } catch (error) {
      }
    }

    return (
      <Image src={icon} fluid className={this.props.className} />
    )

  }
}

export default BankLogo;