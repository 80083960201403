import React from 'react';
import ReactGA from 'react-ga4';

class GA extends React.Component {

  componentDidMount() {

    if (process.env.REACT_APP_GA) {
      ReactGA.initialize(process.env.REACT_APP_GA)
    }


  }

  render() {
    return (
      <>
      </>
    )
  }
}

export default GA