import React from 'react';
import { Row, Col, Card, Button, Modal, Image, Table, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash'

import '../assets/css/main.css'


import request from '../libs/request';
import utility from '../libs/utility';

import Layout from '../component/layout/member';
import Intro from '../component/intro';

import CONST from '../constant'


//import config from '../config';
//const CONFIG = config[ process.env.NODE_ENV || 'development' ];

/*
const MIRROR_URL = [
  'http://ufa6666.com', 'http://ufa7777.com', 'http://ufa9999.com', 'http://uf99999.com', 'http://kingbeer.net'
]
*/


const cookies = new Cookies();

class Point extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);
    let system = cookies.get(process.env.REACT_APP_COOKIE_SYSTEM);

    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
        cookies.set(process.env.REACT_APP_COOKIE_DOWNLINE, { code: refer });
      }
    }

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      //password: data?(CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8):'',
      //password: 'Pwd03205',
      intro: null,
      point: 0,
      prizes: [],
      member_prizes: [],
      prize: null,
      showPrizeModal: false,
      showPrizeConfirmModal: false,
      showAddressForm: false,
      showHistory: false,
      address: null,
      system,
      go: null,
    }

    //console.log('password', (CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8) )

    this.loadData = this.loadData.bind(this);
    this.viewPrize = this.viewPrize.bind(this);
    this.hidePrizeModal = this.hidePrizeModal.bind(this);
    this.hidePrizeConfirmModal = this.hidePrizeConfirmModal.bind(this);
    this.hideHistoryModal = this.hideHistoryModal.bind(this);
    this.buyPrize = this.buyPrize.bind(this);
    this.confirmBuyPrize = this.confirmBuyPrize.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }


  componentDidMount() {
    this.loadData()
  }


  loadData() {
    this.setState({ loading: true }, () => {

      request.get('page/point').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              let data = {
                username: result.data.username,
                point: result.data.point,
                intro: result.data.intro,
                prizes: result.data.prizes,
                member_prizes: result.data.member_prizes || [],
              }

              this.setState(data)

            }
            else {
              if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
              else alert(result.message);

            }

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }



  viewPrize(id) {
    const prize = find(this.state.prizes, { id })
    if (prize) {
      this.setState({ prize, showPrizeModal: true, showAddressForm: false, address: null })
    }
  }

  buyPrize() {
    const { prize, address } = this.state
    if (prize) {

      if (prize.type === 'product' && address === null) {
        this.setState({ showAddressForm: true })
      }
      else {
        this.setState({ showPrizeConfirmModal: true })
      }

    }
  }


  confirmBuyPrize() {
    const { prize, address } = this.state

    if (prize) {

      this.setState({ loading: true, showPrizeConfirmModal: false }, () => {

        let data = {
          prize: prize.id,
          address: address,
        }

        request.post('point/prize', data).then(response => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            this.setState({ loading: false }, () => {

              if (result.success) {
                alert('แลกคะแนนเรียบร้อย')
                this.loadData();

                this.hidePrizeModal()
              }
              else {
                if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
                else alert(result.message);

              }

            });

          }
          else {
            console.log('Cannot register');
          }
        })

      })

    }
  }


  hidePrizeModal() {
    this.setState({ showPrizeModal: false, prize: null, address: null, showAddressForm: false, showPrizeConfirmModal: false })
  }

  hidePrizeConfirmModal() {
    this.setState({ showPrizeConfirmModal: false })
  }

  hideHistoryModal() {
    this.setState({ showHistory: false })
  }

  changeValue(e, field) {
    let value = e.currentTarget.value || '';

    this.setState({ [field]: value })
  }


  render() {
    const { loading, logined, intro, point, username, prizes, prize, showPrizeModal, showAddressForm, showPrizeConfirmModal, showHistory, member_prizes } = this.state;


    return (
      <>

        <Layout loading={loading} source={utility.getQueryString(this.props, CONST.QUERY_SOURCE)}>
          {logined ? (
            <>
              <Row className="justify-content-md-center ">
                <Col sm={12} lg={6} xs={12} >
                  <h3 className="h">คะแนน</h3>

                  <Intro intro={intro} />

                  <Card className="my-4">
                    <Card.Body>
                      <p>ยินดีต้อนรับ <b>{username}</b></p>

                      <b><h4 className="text-info">คะแนน</h4> </b>

                      {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                      ) : (
                        <>
                          {(point === -999) ? (
                            <div className="text-danger my-2">ไม่สามารถแสดงรายการได้ขณะนี้</div>
                          ) : (
                            <>
                              <h2 className="my-2">{point} </h2>

                              {(member_prizes.length > 0) && <Button variant="link" onClick={() => { this.setState({ showHistory: true }) }}>คลิกเพื่อดูประวัติการแลกคะแนน</Button>}
                            </>
                          )}
                        </>
                      )}

                    </Card.Body>
                  </Card>

                </Col>

              </Row>


              {(prizes.length > 0) &&
                <>
                  <hr className="gold" />
                  <Row className="justify-content-md-center ">
                    <Col sm={12} lg={6} xs={12} >
                      <h4 className="h">ของรางวัล</h4>

                    </Col>
                  </Row>
                  <Row className="justify-content-md-center ">
                    {prizes.map((prize, i) => (
                      <Col lg={3} sm={6} xs={12} key={i} className="mb-4">
                        <Card >
                          {prize.photo && <Card.Img variant="top" src={prize.photo} style={{ maxHeight: 220 }} />}
                          <Card.Body>
                            <Card.Title>{prize.name}</Card.Title>
                            <Card.Text>
                              <span className="text-info"><b>{prize.point} คะแนน</b></span>
                              {(prize.remain <= 0) && <span className="text-danger ml-4">หมด</span>}
                              <br />
                              {prize.excerpt}
                            </Card.Text>
                            <Button variant="primary" block onClick={() => this.viewPrize(prize.id)}>ดูรายละเอียด</Button>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <div><p> &nbsp; </p></div>
                </>
              }



              {prize &&
                <Modal
                  show={showPrizeConfirmModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hidePrizeModal}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Body className="text-center p-4">

                    <h4 className="text-info">ต้องการแลกคะแนนหรือไม่?</h4>
                    <h5 className="mb-4">ใช้คะแนน {prize.point}</h5>



                    <Button variant="outline-secondary" className="mx-2" disabled={loading} onClick={this.hidePrizeConfirmModal}>ยกเลิก</Button>
                    <Button variant="primary" className="mx-2" disabled={loading} onClick={this.confirmBuyPrize}>ยืนยัน</Button>

                  </Modal.Body>

                </Modal>
              }


              {prize &&

                <Modal
                  show={showPrizeModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hidePrizeModal}
                  backdrop="static"
                  keyboard={false}
                  centered
                  fullscreen="true"
                  scrollable={true}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{prize.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body >

                    {prize.photo && <><Image fluid src={prize.photo} /><hr /></>}

                    <h4 className="text-info"><b>{prize.point} คะแนน</b></h4>

                    {(prize.remain <= 0) && <h4 className="text-danger">หมด</h4>}

                    {(!showAddressForm && prize.description) && <div dangerouslySetInnerHTML={{ __html: prize.description }}></div>}

                    {showAddressForm &&
                      <>
                        <div className="mb-2">กรุณากรอกที่อยู่สำหรับจัดส่ง</div>
                        <textarea className="form-control" rows="3" onChange={(e) => this.changeValue(e, 'address')}></textarea>
                      </>
                    }

                  </Modal.Body>

                  <Modal.Footer className="text-center">
                    {prize.allow && <Button variant="info" size="lg" block disabled={loading} onClick={this.buyPrize}>แลกคะแนน</Button>}

                    <Button variant="outline-secondary" block disabled={loading} onClick={this.hidePrizeModal}>ปิด</Button>
                  </Modal.Footer>
                </Modal>
              }



              <Modal
                show={showHistory}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={this.hideHistoryModal}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen="true"
                scrollable={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>ประวัติการแลกคะแนน</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: 0 }}>
                  <Table responsive size="sm">
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        <th>วันที่</th>
                        <th>ของรางวัล</th>
                        <th>คะแนน</th>
                        <th>สถานะ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {member_prizes.map((prize, i) => (
                        <tr key={i} className="text-center">
                          <td>{i + 1}</td>
                          <td>{prize.createdAt}</td>
                          <td>{prize.prize.name}</td>
                          <td>{prize.point}</td>
                          <td>
                            {prize.status === 'wait' && <Badge variant="warning">รอดำเนินการ</Badge>}
                            {prize.status === 'cancel' && <Badge variant="secondary">ยกเลิก</Badge>}
                            {prize.status === 'complete' && <Badge variant="success">สำเร็จ</Badge>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Modal.Body>

                <Modal.Footer className="text-center">
                  <Button variant="outline-secondary" block disabled={loading} onClick={this.hideHistoryModal}>ปิด</Button>
                </Modal.Footer>
              </Modal>

            </>
          ) : (<Redirect to="/signin" />)}


        </Layout>

      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Point);