import axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

axios.interceptors.response.use(
  response => response,
  error => {
    //console.error(error);
    return Promise.reject({
      status: (error.response && error.response.status) ? error.response.status : null,
      data: (error.response && error.response.data) ? error.response.data : null,
    });
  }
);


const get = async (path, data = {}, headers = {}) => {
  if (data && Object.keys(data).length > 0) {
    let args = [];
    for (let i in data) args.push(i + '=' + data[i]);

    path += '?' + args.join('&');
  }

  return await axios.get(url(path), await header(headers));
}

const post = async (path, data, headers = {}) => {
  return await axios.post(url(path), data, await header(headers));
}

const put = async (path, data, headers = {}) => {
  return await axios.put(url(path), data, await header(headers));
}


function url(path) {
  return process.env.REACT_APP_API_URL + path;
  //return process.env.api.url + path;
}

async function header(h) {

  h['x-api-key'] = process.env.REACT_APP_API_KEY;
  h['x-ufasystem'] = process.env.REACT_APP_CODE;

  if (cookies.get(process.env.REACT_APP_COOKIE_MEMBER)) {
    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);
    h['authorization'] = 'Bearer ' + data.token;
  }

  if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) {
    let data = cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE);
    h['x-' + process.env.REACT_APP_CODE] = data.code.replace('@', '');
  }

  const ip = await getIP()
  if (ip) h['x-ufasystem-ip'] = ip

  return { headers: h };
}

async function getIP() {

  if (process.env.NODE_ENV === 'development') {
    return '127.0.0.1'
  }
  else {

    try {

      //const response = await axios.get('https://geolocation-db.com/json/')
      //return response.data.IPv4

    } catch (error) {
    }
  }


  return null
}


export default {
  get,
  post,
  put,
}