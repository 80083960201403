
module.exports = {


  getQueryString: (props, field) => {
    let data = null

    if (props.location && props.location.search) {

      const query = new URLSearchParams(props.location.search)

      data = query.get(field)

    }

    return data
  }


  , formatMoney: (number, decPlaces = 2) => {
    if (!isNaN(number)) {
      var num = number.toFixed(decPlaces).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return num;
    }
    return '';
  }

}