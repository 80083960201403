import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { Helmet } from 'react-helmet';

import reducers from './reducer';

import LoginPage from './view/login';
import MainPage from './view/main';
import RegisterPage from './view/register';
import DepositPage from './view/deposit';
import WithdrawPage from './view/withdraw';
import ReferPage from './view/refer';
import ForgetpasswordPage from './view/forgetpassword';
import GamePage from './view/game';
import PlayPage from './view/play';
import ProfilePage from './view/profile';
import PointPage from './view/point';
import WinnerPage from './view/winner';
import PromotionCashBackPage from './view/promotion/cashback/table'


function App() {

  const title = process.env.REACT_APP_NAME
  const description = process.env.REACT_APP_DESCRIPTION



  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Provider store={createStore(reducers)}>
        <BrowserRouter>
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/signin" component={LoginPage} />
          <Route exact path="/winner/:type?" component={WinnerPage} />
          <Route exact path="/deposit" component={DepositPage} />
          <Route exact path="/withdraw" component={WithdrawPage} />
          <Route exact path="/game/:type/:page?" component={GamePage} />
          <Route exact path="/promotion/cashback" component={PromotionCashBackPage} />
          <Route exact path="/refer" component={ReferPage} />
          <Route exact path="/forgetpassword" component={ForgetpasswordPage} />
          <Route exact path="/play" component={PlayPage} />
          <Route exact path="/point" component={PointPage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/" component={MainPage} />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
