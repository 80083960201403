import React from 'react';
import { Row, Col, Card, Alert, InputGroup, FormControl, Form, Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import _ from 'lodash';

import '../assets/css/main.css'

import request from '../libs/request';
import Layout from '../component/layout/member';
import Intro from '../component/intro';

moment.locale('th');

const cookies = new Cookies();
const MONTHS = [{ id: 1, name: 'มกราคม' }, { id: 2, name: 'กุมภาพันธ์' }, { id: 3, name: 'มีนาคม' }, { id: 4, name: 'เมษายน' }, { id: 5, name: 'พฤษภาคม' }, { id: 6, name: 'มิถุนายน' }, { id: 7, name: 'กรกฎาคม' }, { id: 8, name: 'สิงหาคม' }, { id: 9, name: 'กันยายน' }, { id: 10, name: 'ตุลาคม' }, { id: 11, name: 'พฤศจิกายน' }, { id: 12, name: 'ธันวาคม' }];

class Refer extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    let reference = '';
    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
        cookies.set(process.env.REACT_APP_COOKIE_DOWNLINE, { code: refer });
      }
      else reference = refer;
    }

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      promotion: {},
      reference: reference,
      logs: [],
      url_refer: process.env.REACT_APP_URL + '/register',
      current_period: moment().format('YYYY-MM'),// moment().format('M'),
      periods: [],
      showModal: false,
      pathname: null,
      intro: null,
    }

    this.share = this.share.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.loadMember = this.loadMember.bind(this);

  }

  componentDidMount() {

    this.setState({ loading: true, pathname: this.props.location.pathname }, () => {

      request.get('page/refer').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              let periods = [];
              let period_start = '2019-01';
              let period_finish = moment().add(1, 'month').format('YYYY-MM');
              while (period_start !== period_finish) {
                let this_time = moment(period_start + '-01');
                let month = _.find(MONTHS, { id: parseInt(this_time.format('M')) });
                if (month) {
                  periods.push({ title: month.name + ' ' + this_time.format('YYYY'), value: this_time.format('YYYY-MM') })
                }
                period_start = this_time.add(1, 'month').format('YYYY-MM');
              }

              periods.reverse();


              let url_refer = result.data.member.system.website;
              if (url_refer.substring(-1) !== '/') url_refer += '/';
              url_refer += 'register?' + result.data.member.username;
              //url_refer += 'register?'+result.data.promotion.code;


              this.setState({
                member: result.data.member,
                promotion: result.data.promotion,
                logs: [],// result.data.members,
                periods,
                url_refer,
                intro: result.data.intro,
              }, this.loadMember)

            }
            else {

              if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
              else alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot load data');
        }
      })

    })
  }


  loadMember() {
    this.setState({ loading: true }, () => {

      request.get('member/reference/' + this.state.current_period).then(response => {

        this.setState({ loading: false }, () => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            if (result.success) {

              this.setState({ logs: result.data.members })

            }
            else alert(result.message);

          }
          else {
            console.log('Cannot load data');
          }
        })

      });


    })
  }

  share() {
    //https://member.ufamax.com/signup/ABCAHG สมัคร UFABET 20 บาทก็เล่นได้!! เล่นตรงไม่ผ่านเอเย่นต์ ระบบฝาก-ถอนออโต้ 20 วิ

    let refer_url = this.state.url_refer;//+'/register?'+this.state.promotion.code;

    let message = refer_url + ' สมัคร UFABET 20 บาทก็เล่นได้!! เล่นตรงไม่ผ่านเอเย่นต์ ระบบฝาก-ถอนออโต้ 20 วิ';

    //https://social-plugins.line.me/lineit/share?url=https://member.ufamax.com/invite&text=https%3A%2F%2Fmember.ufamax.com%2Fsignup%2FABCAHG%20%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%20UFABET%2020%20%E0%B8%9A%E0%B8%B2%E0%B8%97%E0%B8%81%E0%B9%87%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%99%E0%B9%84%E0%B8%94%E0%B9%89!!%20%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%99%E0%B8%95%E0%B8%A3%E0%B8%87%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%9C%E0%B9%88%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%AD%E0%B9%80%E0%B8%A2%E0%B9%88%E0%B8%99%E0%B8%95%E0%B9%8C%20%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%9D%E0%B8%B2%E0%B8%81-%E0%B8%96%E0%B8%AD%E0%B8%99%E0%B8%AD%E0%B8%AD%E0%B9%82%E0%B8%95%E0%B9%89%2020%20%E0%B8%A7%E0%B8%B4&from=line_scheme
    let url = 'https://social-plugins.line.me/lineit/share?url=' + refer_url + '&text=' + message + '&from=line_scheme';


    window.open(url, "_blank");
  }

  changeMonth(e) {
    this.setState({ current_period: e.currentTarget.value }, this.loadMember);
  }

  handleFocus = (event) => event.target.select();

  render() {
    const { loading, logined, promotion, url_refer, logs, periods, pathname, intro } = this.state;

    let refer_url = url_refer;
    //if(promotion && promotion.code) refer_url += '?'+promotion.code;

    return (
      <>


        {logined ? (
          <>

            <Layout path={pathname}>
              <Row className="justify-content-md-center ">
                <Col sm={12} lg={6} xs={12} >
                  <h3 className="h">ชวนเพื่อน</h3>

                  <Intro intro={intro} />

                  {(promotion && promotion.code) ? (
                    <>
                      <Card className="my-4">
                        <Card.Body>
                          <InputGroup className="mb-3">
                            <FormControl
                              defaultValue={refer_url}
                              onFocus={this.handleFocus}
                              readOnly
                            />
                            <InputGroup.Append>
                              <Button variant="success" onClick={this.share}><FontAwesomeIcon icon={faShareAlt} /> แชร์ให้เพื่อน</Button>
                            </InputGroup.Append>
                          </InputGroup>

                        </Card.Body>
                      </Card>

                      <Alert variant="success">
                        {promotion.description}
                      </Alert>

                      <Card className="my-4">
                        <Card.Body>
                          <Row className="mb-2">
                            <Col>
                              <b>รายชื่อผู้ที่แนะนำ</b>
                            </Col>

                            <Col className="text-right" style={{ display: 'none' }}>
                              <Form.Control as="select" size="sm" onChange={this.changeMonth}>
                                {periods.map((period, i) => (<option key={i} value={period.value}>{period.title}</option>))}
                              </Form.Control>
                            </Col>
                          </Row>

                          {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (

                            <Table striped hover className="table-sm">
                              <thead>
                                <tr className="text-center">
                                  <th>สมาชิก</th>
                                  <th>ชื่อ</th>
                                  <th>วันที่สมัคร</th>
                                  <th>เครดิตฟรี</th>
                                </tr>
                              </thead>
                              <tbody>
                                {logs.map((log, i) => (
                                  <tr key={i} className="text-center">
                                    <td>{log.username}</td>
                                    <td>{log.firstname}</td>
                                    <td>{log.createdAt}</td>
                                    <td>{(log.referCredit > 0) ? log.referCredit : '-'}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                          )}
                        </Card.Body>
                      </Card>

                    </>
                  ) : (<Alert variant="warning">ยังไม่มีข้อมูลโปรโมชั่นสำหรับรับเครดิตฟรีในขณะนี้</Alert>)}

                </Col>
              </Row>

            </Layout>


          </>
        ) : (<Redirect to={'/signin?path=' + pathname} />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Refer);