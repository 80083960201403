import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import '../../../assets/css/main.css'

import request from '../../../libs/request';

const cookies = new Cookies();

class CashbackConfirm extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      showModal: false,
      cashback_data: null,
      haveCashback: false,
    }

    this.confirm = this.confirm.bind(this)
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {

    if (this.props.cashback) {

      //console.log('Cashback', this.props.cashback)

      this.setState({
        logined: true,
        showModal: true,
        cashback_data: this.props.cashback,
        haveCashback: (this.props.cashback.cashback > 0),
      });

    }

  }


  hideModal(reload = false) {
    this.setState({ showModal: false }, () => {

      if (this.props.onFinish) {
        this.props.onFinish();
      }

    });
  }

  confirm() {

    const { cashback_data } = this.state;
    if (cashback_data.status === 'wait') {

      this.setState({ loading: true }, async () => {
        let data = {
          cashbackid: this.state.cashback_data.id,
        }

        request.post('promotion/cashback/confirm', data).then(response => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            this.setState({ loading: false }, () => {

              if (result.success) {

                if (this.state.haveCashback) alert('ยืนยันรับคืนยอดเสีย เรียบร้อยแล้ว')

                window.location.reload();
              }
              else {
                alert(result.message);
              }

              this.hideModal(true);
            });

          }
          else {
            console.error('ไม่สามารถบันทึกข้อมูลได้ขณะนี้');
          }
        })


      })

    } else {
      this.hideModal();
    }

  }


  render() {
    const { loading, logined, showModal, cashback_data, haveCashback } = this.state;

    let alreadyConfirm = true;
    if (cashback_data && cashback_data.status === 'wait') alreadyConfirm = false;

    return (
      <>


        {logined ? (
          <>
            <Modal show={showModal} onHide={this.hideModal} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
              <Modal.Header>
                <Modal.Title>คืนยอดเสีย</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                {cashback_data &&
                  <div className="text-center">

                    {haveCashback &&
                      <>
                        <h4 className="text-success mb-4">ได้รับยอดเสียคืน</h4>

                        <h5 className=" mb-4">จำนวน {cashback_data.cashback} บาท</h5>
                        <p>โดยตั้งแต่วันที่ {cashback_data.start} ถึง {cashback_data.end}</p>

                        <p>มียอดเสียจำนวน {cashback_data.summary} บาท</p>
                      </>
                    }

                    {!haveCashback &&
                      <>
                        <h4 className="text-warning mb-4">ไม่ได้รับยอดเสีย</h4>
                        <p>เนื่องจากยอดเสียไม่ถึงขั้นต่ำที่กำหนด {cashback_data.condition.lost} บาท</p>
                        <p>โดยตั้งแต่วันที่ {cashback_data.start} ถึง {cashback_data.end}</p>
                        <p>{(cashback_data.summary >= 0) ? 'มียอดเสียจำนวน' : 'มีกำไรจำนวน'} <span className="text-info">{cashback_data.summary}</span> บาท</p>
                      </>
                    }

                  </div>
                }


              </Modal.Body>
              <Modal.Footer>
                {loading ? (
                  <div className="text-center"><FontAwesomeIcon icon={faSpinner} spin size="lg" /></div>
                ) : (
                  <>
                    {haveCashback &&
                      <>
                        {!alreadyConfirm && <Button variant="primary" type="button" className="px-4 btn-lg btn-block btn-primary" size="lg" onClick={this.confirm}>ยืนยันรับคืนยอดเสีย</Button>}
                        <Button variant="outline-secondary btn-block" type="button" onClick={this.hideModal}>ปิด</Button>
                      </>
                    }

                    {!haveCashback &&
                      <Button variant="outline-secondary btn-block" size="lg" type="button" onClick={this.confirm}>ปิด</Button>
                    }

                  </>
                )}
              </Modal.Footer>
            </Modal>

          </>
        ) : (<Redirect to="/signin" />)
        }



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(CashbackConfirm);