import React from 'react';
import { Navbar, Nav, Container, Alert, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUser, faUserPlus, faKey, faSignOutAlt, faHandHoldingUsd, faMoneyBillWave, faWallet, faCoins } from '@fortawesome/free-solid-svg-icons'
import LoadingOverlay from 'react-loading-overlay'

import Cookies from 'universal-cookie';

import request from '../../libs/request';



const cookies = new Cookies();

class Layout extends React.Component {

  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);
    let system = cookies.get(process.env.REACT_APP_COOKIE_SYSTEM);

    this.state = {
      loading: false,
      logined: data ? true : false,
      logout: false,
      system,
      line_liffid: null,
      pathname: '/',
      hideBottom: false,
    }

    this.logout = this.logout.bind(this);
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.source && !this.state.line_liffid) {

      console.log('Source', nextProps.source)
    }

    if (nextProps && nextProps.path) {
      this.setState({ pathname: nextProps.path })
    }

    if (nextProps && typeof nextProps.loading !== 'undefined') {
      console.log('nextProps.loading', nextProps.loading)
      this.setState({ loading: nextProps.loading })

    }

    if (nextProps && typeof nextProps.hideBottom !== 'undefined') {
      this.setState({ hideBottom: nextProps.hideBottom })
    }
  }

  logout() {
    request.post('member/logout').then(response => {

      cookies.remove(process.env.REACT_APP_COOKIE_MEMBER);
      this.setState({ logined: false, logout: true });

    })

  }

  render() {
    const { logined, logout, system, pathname, loading, hideBottom } = this.state;

    return (
      <>

        {logout && <Redirect to={'/signin?path=' + pathname} />}


        <LoadingOverlay active={loading} spinner>

          {(system && system.configuration && system.configuration.maintenance) &&
            <>
              <Row className="justify-content-md-center ">
                <Col sm={4}  >
                  <Alert variant="warning">
                    <div dangerouslySetInnerHTML={{ __html: system.configuration.message ? system.configuration.message.maintenance || 'ปิดปรับปรุงระบบ' : 'ปิดปรับปรุงระบบ' }}></div>
                  </Alert>
                </Col>
              </Row>
            </>
          }

          <Navbar bg="dark" variant="dark" fixed="top">
            <Container>
              <Navbar.Brand href="#home">{system ? system.name.toUpperCase() : ''}</Navbar.Brand>

              <Navbar.Collapse className="justify-content-end">
                <Nav.Link onClick={this.logout} style={{ color: 'white' }}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </Nav.Link>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {this.props.children}


          {!hideBottom &&
            <Navbar bg="dark" variant="dark" fixed="bottom">
              <Container>

                {logined ? (
                  <Nav fill  >
                    <Nav.Item>
                      <Link to="/" className="nav-link">
                        <FontAwesomeIcon icon={faUser} /><br />บัญชีผู้ใช้
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link to="/deposit" className="nav-link">
                        <FontAwesomeIcon icon={faWallet} /><br />เติมเครดิต
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link to="/withdraw" className="nav-link" >
                        <FontAwesomeIcon icon={faMoneyBillWave} /><br />ถอนเงิน
                      </Link>
                    </Nav.Item>
                    <Nav.Item style={{ display: 'none' }}>
                      <Link to="/point" className="nav-link" >
                        <FontAwesomeIcon icon={faCoins} /><br />คะแนน
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link to="/refer" className="nav-link">
                        <FontAwesomeIcon icon={faHandHoldingUsd} /><br />ชวนเพื่อน
                      </Link>
                    </Nav.Item>
                  </Nav>
                ) : (
                  <Nav fill >
                    <Nav.Item className="mx-4">
                      <Link to="/signin" className="nav-link">
                        <FontAwesomeIcon icon={faSignInAlt} /><br />เข้าสู่ระบบ
                      </Link>
                    </Nav.Item>
                    <Nav.Item className="mx-4">
                      <Link to="/register" className="nav-link">
                        <FontAwesomeIcon icon={faUserPlus} /><br />สมัครสมาชิก
                      </Link>
                    </Nav.Item>
                    <Nav.Item className="mx-4">
                      <Link to="/forgetpassword" className="nav-link">
                        <FontAwesomeIcon icon={faKey} /><br />ลืมรหัสผ่าน
                      </Link>
                    </Nav.Item>
                  </Nav>
                )}

              </Container>
            </Navbar>

          }

        </LoadingOverlay>
      </>
    );
  }
}




const mapStateToProps = state => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(Layout);