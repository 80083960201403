import React from 'react';
import { Row, Col, Card, Button, Modal, Table, Image, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle, faCopy } from '@fortawesome/free-solid-svg-icons'
import { saveAs } from 'file-saver';

import '../assets/css/main.css'

import request from '../libs/request';
import Layout from '../component/layout/member';
import Intro from '../component/intro'
import BankLogo from '../component/bank_logo'

const cookies = new Cookies();


const CHANNEL_QRCODE = ['TRINITY', 'PROMPTPAY']

class Deposit extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      logs: [],
      showModal: false,
      intro: null,
      limit: 20,
      deposit_amount: 0,
      deposit_min: 100,
      deposit_limit: { min: 0, max: 20000 },
      pathname: null,
    }

    this.hideModal = this.hideModal.bind(this);
    this.changeDepositAmount = this.changeDepositAmount.bind(this);
    this.submitDeposit = this.submitDeposit.bind(this);
    this.downloadQRCode = this.downloadQRCode.bind(this);
    this.copyText = this.copyText.bind(this);
  }

  componentDidMount() {

    this.setState({ loading: true, pathname: this.props.location.pathname }, () => {

      request.get('page/deposit?limit=' + this.state.limit).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              const system = result.data.system;

              let data = {
                showModal: false,
                member: result.data.member,
                system,
                logs: result.data.logs,
                deposit_limit: result.data.limit,
                intro: result.data.intro,
              }

              //if (system.configuration.message && system.configuration.message.deposit) data.intro = system.configuration.message.deposit;

              /*
              for (const account of system.accounts) {
                if (CHANNEL_QRCODE.indexOf(account.bank.id) === -1) data.showModal = true;
              }
                */


              if (system.configuration.video && system.configuration.video.deposit) data.video = system.configuration.video.deposit


              this.setState(data);

            }
            else {

              if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
              else alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }


  hideModal() {
    this.setState({ showModal: false }, () => {
    });
  }

  changeDepositAmount(e) {
    let amount = e.currentTarget.value || 0;
    this.setState({ deposit_amount: amount });
  }

  submitDeposit(bank) {
    let { deposit_amount, deposit_limit } = this.state

    if (deposit_amount < deposit_limit.min) {
      alert('จำนวนเงินฝากต้องไม่น้อยกว่า ' + deposit_limit.min + ' บาท')
    }
    else if (deposit_amount > deposit_limit.max) {
      alert('จำนวนเงินฝากต้องไม่เกิน ' + deposit_limit.max + ' บาท')
    }
    else {
      //console.log('Bank', bank, this.state.deposit_amount)

      request.post('credit/deposit/' + bank, { amount: this.state.deposit_amount }).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              //console.log('data', result.data)

              let data = result.data

              if (data.redirect_url) {
                window.location.href = data.redirect_url

                //window.open(data.redirect_url)
              }
              else {
                alert('ทำรายการแจ้งฝากเงินเรียบร้อยแล้ว')
                window.location.reload();
              }
              //

            }
            else alert(result.message);

          });

        }
        else {
          console.log('Cannot register');
        }
      })
    }

  }


  downloadQRCode(qrcode) {
    const filename = qrcode.split('/').pop()

    saveAs(qrcode, filename)
  }

  copyText(text) {
    navigator.clipboard.writeText(text)
  }


  render() {
    const { loading, logined, username, showModal, system, member, logs, intro, pathname } = this.state;


    return (
      <>


        {logined ? (
          <>


            {member.account &&
              <>

                <Layout path={pathname}>
                  <Row className="justify-content-md-center ">
                    <Col sm={12} lg={6} xs={12} >
                      <h3 className="h">เติมเครดิต</h3>
                      <Card className="my-4">
                        <Card.Body>
                          <p>ยินดีต้อนรับ <b>{username}</b></p>
                          บัญชีของคุณ
                          <span className="text-success mx-2">{member.firstname} {member.lastname}</span>

                          {/*
                          <>
                          <span className="text-success mx-2">{member.account.bank.name} {member.account.number}</span>
                          (<span className="text-success mx-2">{member.firstname} {member.lastname}</span>)
                          </>
                           */}

                        </Card.Body>
                      </Card>

                      <Intro intro={intro} />

                      {system.accounts &&
                        <>
                          {system.accounts.map((account, i) => (
                            <Card className="my-1 mb-3" key={i}>
                              {account.by &&
                                <Card.Header>
                                  ต้องโอนด้วย <span className="text-primary">{account.by.bank.name} {account.by.number}</span>
                                </Card.Header>
                              }
                              <Card.Body>
                                <Form className="mb-3">
                                  <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm={4} xs={4} lg={4}>
                                      {(account.bank.id !== 'truewallet') ? 'ธนาคาร' : 'เข้า'}
                                    </Form.Label>
                                    <Col >
                                      <Form.Control className="text-success" plaintext readOnly defaultValue={account.bank.name} />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm={4} xs={4} lg={4}>
                                      {(account.bank.id === 'truewallet') ? 'เบอร์/ID' : 'เลขที่บัญชี'}
                                    </Form.Label>
                                    <Col >
                                      <Form.Control className="text-success" plaintext readOnly defaultValue={account.number} />
                                    </Col>
                                    <Col>
                                      <Button size="sm" variant="outline-info" onClick={() => this.copyText(account.number)}><FontAwesomeIcon icon={faCopy} /> Copy</Button>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} >
                                    <Form.Label column sm={4} xs={4} lg={4}>
                                      ชื่อบัญชี
                                    </Form.Label>
                                    <Col >
                                      <Form.Control className="text-success" plaintext readOnly defaultValue={account.name || '-'} />
                                    </Col>
                                  </Form.Group>
                                </Form>
                                {(account.qrcode && account.qrcode.show && account.qrcode.url) ? (
                                  <>

                                    <Row>
                                      <Col className="text-center">
                                        <Image src={account.qrcode.url + '?' + Date.now()} className="mb-2" style={{ maxWidth: 250 }} />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="text-center">
                                        <Button className="px-4" variant="outline-info" onClick={() => { this.downloadQRCode(account.qrcode.url + '?' + Date.now()) }} >Save QR Code</Button>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (

                                  <Row style={{ display: 'none' }}>
                                    <Col xs={3} sm={3} lg={2}>
                                      <BankLogo bank={account.bank.id} />
                                    </Col>
                                    <Col>

                                      <h4>{(account.bank.id !== 'truewallet') ? 'ธนาคาร' : ''} {account.bank.name}</h4>


                                      <>
                                        {(account.bank.id === 'truewallet') ? (
                                          <>
                                            <span>
                                              เบอร์/ID <b className="text-success">{account.number}</b><br />
                                              ชื่อ <b className="text-success">{account.name || '-'}</b>

                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            เลขที่บัญชี <b className="text-success">{account.number}</b> <br />ชื่อบัญชี <b className="text-success">{account.name}</b> <br />
                                          </>
                                        )}
                                      </>


                                    </Col>
                                  </Row>
                                )}

                              </Card.Body>
                            </Card>
                          ))}
                        </>
                      }

                      {(logs.length > 0) &&
                        <Card className="my-2">
                          <Card.Body>
                            <div className="mb-2">
                              <b>{this.state.limit} รายการล่าสุด</b>
                            </div>

                            {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (

                              <Table striped hover className="table-sm">
                                <thead>
                                  <tr className="text-center">
                                    <th>วันที่</th>
                                    <th >ประเภท</th>
                                    <th>จำนวน</th>
                                    <th >สถานะ</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {logs.map((log, i) => (
                                    <tr key={i} className="text-center">
                                      <td><small>{log.createdAt}</small></td>
                                      <td>{log.type}</td>
                                      <td>{log.credit} บาท</td>
                                      <td><span className={'text-' + log.status.mode}>{log.status.title}</span></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>

                            )}

                          </Card.Body>
                        </Card>
                      }

                    </Col>
                  </Row>

                </Layout>

                <Modal
                  show={showModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hideModal}
                  centered
                >
                  <Modal.Body className="text-center">

                    <FontAwesomeIcon className="mb-4" icon={faInfoCircle} size="6x" />

                    <h4 className="text-info mb-4">โปรดอ่าน</h4>
                    <p>
                      กรุณาใช้บัญชี </p>
                    {system.accounts.map((account, i) => (
                      <>
                        <p>
                          <b class="text-primary">{account.by.bank.name} {account.by.number}</b><br />
                          โอนเข้า<br />
                          <b class="text-success">{account.bank.name} {account.number}</b>
                        </p>
                        <hr />
                      </>
                    ))}

                    {/*
                    <p><b className="text-success">{member.account.bank.name} {member.account.number}</b>
                      <br />
                      <span className="text-success">{member.firstname} {member.lastname}</span></p>
 */}

                    <p>
                      ในการโอนเงินเท่านั้นไม่เช่นนั้นเครดิตจะไม่เข้าบัญชี หากมีปัญหากรุณาติดต่อทีมงาน
                    </p>
                    <div className="text-center">

                      <Button variant="secondary" className="px-4" onClick={this.hideModal}>ปิด</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            }

          </>
        ) : (<Redirect to={'/signin?path=' + pathname} />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Deposit);