import React from 'react';
import { Alert, Image } from 'react-bootstrap';


import Video from './video'


class Intro extends React.Component {

  render() {
    const intro = this.props.intro

    return (
      <>
        {intro &&
          <>
            {intro.description &&
              <Alert variant="warning" className="mb-2">
                <div dangerouslySetInnerHTML={{ __html: intro.description }}></div>
              </Alert>
            }

            {intro.image && <Image src={intro.image} fluid className="my-2" />}

            {intro.video && <Video url={intro.video} className="my-2" />}
          </>
        }
      </>
    )
  }
}


export default Intro;