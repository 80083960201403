import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
//import CryptoJS from 'crypto-js';

import '../assets/css/main.css'


import request from '../libs/request';
import utility from '../libs/utility';

import Layout from '../component/layout/member';

import CONST from '../constant'


//import config from '../config';
//const CONFIG = config[ process.env.NODE_ENV || 'development' ];

/*
const MIRROR_URL = [
  'http://ufa6666.com', 'http://ufa7777.com', 'http://ufa9999.com', 'http://uf99999.com', 'http://kingbeer.net'
]
*/


const cookies = new Cookies();

class Play extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);
    let system = cookies.get(process.env.REACT_APP_COOKIE_SYSTEM);

    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
        cookies.set(process.env.REACT_APP_COOKIE_DOWNLINE, { code: refer });
      }
    }

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      //password: data?(CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8):'',
      //password: 'Pwd03205',
      credit: 0,
      point: 0,
      goDeposit: false,
      loadingUFABET: false,
      //ufabet_fields: [],
      system,
      is_joker: (system && system.domain === 'joker'),
      mirror: [],
      showChangePassword: false,
      password_old: '',
      password_new: '',
      intro: null,
      games: [],
      game: null,
      goGame: null,
      go: null,
      pathname: null,
    }

    //console.log('password', (CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8) )


    this.enterGameSite = this.enterGameSite.bind(this);
  }


  componentDidMount() {

    this.setState({ loading: true }, () => {

      request.get('page/play').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              let data = {
                mirror: result.data.mirror_site,
              };

              this.setState(data, () => {
                this.enterGameSite()
              });

            }
            else {
              if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
              else alert(result.message);

            }

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }

  enterGameSite() {
    const this_url = this.state.mirror[_.random(0, this.state.mirror.length - 1)];

    //let url = url_ufabet + '/Default8.aspx?lang=EN-GB';
    //console.log(url)
    //this.loginUfabet.current.click();

    this.setState({ loading: true }, () => {

      request.post('ufabet/access', {}).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {
            let redirect_url = this_url

            if (result.success) {

              if (result.data.url) {
                redirect_url = result.data.url

                //window.location.href = result.data.url;
              }
              else {
                //window.location.href(this_url);
              }



            }
            else {
              console.log(result.message);
              //window.location.href(this_url);
            }

            window.location = redirect_url
          });

        }
        else {
          console.log('Cannot access');
          window.location.href(this_url);
        }
      }).catch(error => {
        this.setState({ loading: false });
      })

    })

  }


  render() {
    const { loading, logined } = this.state;


    return (
      <>

        <Layout loading={loading} source={utility.getQueryString(this.props, CONST.QUERY_SOURCE)}>
          {logined ? (
            <>
              <Row className="justify-content-md-center ">
                <Col sm={12} lg={6} xs={12} >

                  <Card className="my-4">
                    <Card.Body className="text-center py-4">
                      <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                    </Card.Body>
                  </Card>

                </Col>

              </Row>


            </>
          ) : (<Redirect to="/signin" />)}


        </Layout>

      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Play);