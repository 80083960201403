import React from 'react';

class Line extends React.Component {

  render() {
    let url = null;
    if (this.props.id) url = 'https://line.me/R/ti/p/@' + this.props.id
    //if (process.env.REACT_APP_LINE) url += process.env.REACT_APP_LINE;

    let qrcode = null;
    //url = null
    //if(process.env.REACT_APP_LINE_QRCODE) qrcode = process.env.REACT_APP_LINE_QRCODE

    let icon = 'https://scdn.line-apps.com/n/line_add_friends/btn/th.png'
    //icon = require('../assets/images/add-friend-line.png')


    return (
      <>
        {url &&
          <div className="text-center">
            {qrcode &&
              <>
                <a href={url} rel="noopener noreferrer" target="_blank"><img src={qrcode} alt="Add Line" width="180" className="mb-2" /></a>
                <br />
              </>
            }
            <a href={url} rel="noopener noreferrer" target="_blank"><img src={icon} alt="Add Line" width="180" /></a>
          </div>
        }
      </>
    )
  }
}


export default Line