import React from 'react';
import { Row, Col, Button, Alert, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

import '../assets/css/login.css'
import '../assets/css/nologin.css'

import request from '../libs/request';
import utility from '../libs/utility';

import Layout from '../component/layout/guest';
import TopComponent from '../component/top';



const cookies = new Cookies();


class Winner extends React.Component {
  constructor(props) {
    super(props);


    let reference = '';
    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
        cookies.set(process.env.REACT_APP_COOKIE_DOWNLINE, { code: refer });
      }
      else reference = refer;
    }


    this.state = {
      web_title: null,
      web_description: null,
      loading: false,
      username: '',
      password: '',
      phone: '',
      success: false,
      intro: null,
      reference: reference,
      system: null,
      login_format: 'username',
      redirectTarget: null,
      line_userid: null,
      line_liffid: null,
      pathname: '/',
      isIframe: false,
      type: 'lucky_wheel',
    }

  }


  componentDidMount() {
    if (cookies.get(process.env.REACT_APP_COOKIE_MEMBER)) cookies.remove(process.env.REACT_APP_COOKIE_MEMBER);
    //console.log('path', utility.getQueryString(this.props, 'path'))

    const refer_path = utility.getQueryString(this.props, 'path')
    const req_iframe = utility.getQueryString(this.props, 'iframe')

    let init_data = { loading: true, success: false, pathname: refer_path || '/' }
    if (req_iframe) init_data.isIframe = (req_iframe === 'y')

    if (this.props.match.params.type && this.state.type !== this.props.match.params.type) {
      init_data.type = this.props.match.params.type
    }


    this.setState(init_data, () => {

      request.get('page/login').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              const system = result.data.system;

              let data = { system, login_format: system.configuration.login, intro: result.data.intro };

              //console.log('result.data.intro', result.data.intro)

              let web_title = system.name, web_description = null
              if (system.configuration.seo && system.configuration.seo.login) {
                web_title = system.configuration.seo.login.title
                web_description = system.configuration.seo.login.description
              }

              data.web_title = web_title
              if (web_description) data.web_description = web_description


              //console.log('seo', system.configuration.seo)

              //if (system.configuration.message && system.configuration.message.login) data.intro = system.configuration.message.login;
              //console.log('system.configuration', system.configuration.line)
              this.setState(data, async () => {
                cookies.set(process.env.REACT_APP_COOKIE_SYSTEM, {
                  id: system.id,
                  code: system.code,
                  name: system.name,
                  domain: system.domain || 'ufabet',
                  maintenance: system.configuration.maintenance,
                  login_format: system.configuration.login,
                })

                if (!system.configuration.maintenance && system.configuration.liff && system.configuration.liff.use && system.configuration.liff.id) {

                  const LIFF_ID = system.configuration.liff.id

                  this.setState({ line_liffid: LIFF_ID })

                }
                else {
                  //console.log('Line LIFF ID not found')
                }

              })
            }
            else {
              alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }



  render() {
    const { loading, system, web_title, web_description, isIframe, type } = this.state;

    let logo = require('../assets/images/logo.jpg');
    if (process.env.REACT_APP_LOGO) logo = process.env.REACT_APP_LOGO;
    else if (system && system.logo) logo = system.logo;

    const have_forgetPassword = (system && system.configuration && system.configuration.feature && system.configuration.feature.forgetPassword);


    return (
      <>
        <Layout loading={loading} web_title={web_title} web_description={web_description} submitLogin={this.login}>
          {system &&
            <>

              <Row className="justify-content-md-center " style={{ padding: 0 }}>
                <Col sm={4} >
                  <Card className="login-form">
                    <Card.Body style={{ padding: 0 }}>

                      {!isIframe &&
                        <div className="text-center">
                          <img src={logo} alt={web_title} className="img-fluid mb-2" style={{ maxHeight: 180 }} />
                        </div>
                      }


                      {system.configuration.maintenance ? (

                        <Alert variant="warning">
                          <div dangerouslySetInnerHTML={{ __html: system.configuration.message ? system.configuration.message.maintenance || 'ปิดปรับปรุงระบบ' : 'ปิดปรับปรุงระบบ' }}></div>
                        </Alert>
                      ) : (
                        <>

                          <TopComponent type={type} isPage={true} isIframe={isIframe} />

                          {!isIframe &&
                            <>

                              <br />
                              <hr className="gold" />

                              <div className="row text-center">
                                <div className="col-sm-6 text-center mt-4">
                                  <Button block variant="success" size="lg" onClick={() => { this.setState({ redirectTarget: '/' }) }}>คลิกที่นี่เพื่อเข้าสู่ระบบ</Button>
                                </div>

                                <div className="col-sm-6 text-center mt-4">
                                  <Button block variant="primary" size="lg" onClick={() => { this.setState({ redirectTarget: '/register' }) }}>สมัครสมาชิก</Button>
                                </div>

                                {have_forgetPassword &&
                                  <div className="col-sm-6 text-center mt-4">
                                    <Button block variant="info" size="lg" onClick={() => { this.setState({ redirectTarget: '/forgetpassword' }) }}>ลืมรหัสผ่าน</Button>
                                  </div>
                                }
                              </div>
                            </>
                          }


                        </>
                      )}

                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </>
          }
        </Layout>

      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Winner);