import React from 'react';

export default function Site() {
  let sites = [
    {
      title: 'ข่าวฟุตบอลพรีเมียร์ลีกอังกฤษ ข่าวบอลยูโร ฟุตบอลสเปน ฟุตบอลเยอรมัน ฟุตบอลฝรั่งเศส ฟุตบอลอิตาลี',
      description: 'ข่าวฟุตบอลพรีเมียร์ลีกอังกฤษ ข่าวบอลยูโร ฟุตบอลสเปน ฟุตบอลเยอรมัน ฟุตบอลฝรั่งเศส ฟุตบอลอิตาลี',
      url: 'https://www.dookaoball.com/'
    },
    {
      title: 'Games, Puzzles and Accessories On Sale',
      description: 'Games, Puzzles and Accessories',
      url: 'https://www.gameintrend.com',
    },
    {
      title: 'Mother and Kids Accessories On Sale',
      description: 'Mother and Kids Accessories',
      url: 'https://www.motherandkidsstore.com',
    },
    {
      title: 'Mobile Phones, Parts and Accessories On Sale',
      description: 'Mobile Phones, Parts and Accessories',
      url: 'https://www.mobileintrend.com',
    },
    {
      title: 'Android Smart Phones Wearable Device On Sale',
      description: 'Android Smart Phones Wearable Device',
      url: 'https://www.onsaleandroid.com',
    },
    {
      title: 'Android Smart Phones Wearable Device On Sale',
      description: 'Android Smart Phones Wearable Device',
      url: 'https://www.shoppingintrend.com',
    },
  ];

  //description = '';
  //site_url = '';

  sites = []

  return (
    <>
      <div style={{ marginTop: 30 }} id="divleadsite" className="col-md-4 offset-md-4 col-xs-12 noselect">
        <p style={{ color: '#060402', cursor: 'default' }}><strong><a href="https://www.lenball.com/" style={{ color: '#060402', cursor: 'default' }}>Lenball</a></strong> ให้บริการ ฝาก-ถอน วอเลต ด้วย ระบบอัตโนมัติ&nbsp;<strong>UFABET True Wallet</strong>&nbsp;ที่ ทันสมัยที่สุดในปัจจุบัน เพื่อให้บริการสมาชิกได้อย่างรวดเร็ว เพียง 1 นาที ด้วยระบบ <strong>UFAAUTO</strong> <strong>แ</strong>ละสะดวกยิ่งกว่า ด้วยบริการ&nbsp;<a href="https://www.lenball.com/ufabet-wallet/" style={{ color: '#060402', cursor: 'default' }}><strong>UFABET Wallet</strong></a>&nbsp;ให้สมาชิกสนุกกับเกม สล็อต ฝากถอน ไม่มี ขั้นต่ำ วอเลท กับ เว็บพนัน&nbsp;<strong>ยูฟ่า bet</strong> ที่ <strong>รวมเว็บ สล็อต ออ โต้</strong>&nbsp;เอาไว้ให้บริการมากที่สุด&nbsp;</p>
        {sites.map((site, i) => (
          <div key={i} className="text-center" style={{ width: 200, margin: 'auto' }}>
            <a href={site.url} style={{ color: '#060402', cursor: 'default' }}><h1 style={{ fontSize: 10 }}>{site.description}</h1></a>
          </div>
        ))}
      </div>
    </>
  )
}