import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import request from '../libs/request'

class TopList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showModal: false,
      date: null,
      data: [],
      title: null,
      isPage: false,
      isIframe: false,
      notClose: false,
    }

    this.hideModal = this.hideModal.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    let init_data = {
      notClose: this.props.notClose || false,
      isPage: this.props.isPage || false,
      isIframe: this.props.isIframe || false,
    }


    this.setState(init_data, () => {

      this.loadData(this.props.type);

    })
  }

  loadData(type) {

    this.setState({ loading: true, showModal: true, title: null }, async () => {

      request.get('report/top/' + type).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              //console.log('viewTop', result.data)

              const { date, data } = result.data

              let title = 'ผู้ได้รับรางวัลสูงสุด'
              if (type === 'cashback') title = 'ผู้ได้คืนยอดเสียสูงสุด'

              this.setState({ showModal: true, date, data, title }, () => {

              })

            }
            else {
              alert(result.message);
            }
          });

        }
        else {
          console.log('Cannot load top list');
          this.setState({ loading: false });
        }
      })


    })
  }

  hideModal() {
    this.setState({ showModal: false }, () => {
      if (this.props.onClose) this.props.onClose();
    });
  }

  render() {
    const { loading, title, showModal, date, data: rows, notClose, isPage, isIframe } = this.state

    const have_percent = (rows.length > 0 && rows[0].percentage)

    return (
      <>

        {isPage ? (
          <>

            {loading ? (
              <div className="text-center p-4">
                <FontAwesomeIcon icon={faSpinner} spin size="lg" />
              </div>
            ) : (
              <>
                <div className="text-center">
                  <b>10 อันดับ {title}</b>
                  <br />
                  <b>ประจำวันที่ {date}</b>
                </div>
                <Table striped hover variant="dark" className="mt-4">
                  <thead>
                    <tr className="text-center">
                      <th>อันดับ</th>
                      <th >รหัสผู้ใช้</th>
                      <th>จำนวนเงินที่ชนะ</th>
                      {have_percent && <th>% ได้คืน</th>}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {rows.map((row, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{row.username}</td>
                        <td>{row.amount}</td>
                        {have_percent && <td>{row.percentage}</td>}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {isIframe &&
                  <div className="text-center">
                    <i><a href={process.env.REACT_APP_URL} className="gold" target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_NAME}</a></i>
                  </div>
                }
              </>
            )}

          </>
        ) : (

          <Modal
            show={showModal}
            aria-labelledby="contained-modal-title-vcenter"
            onHide={this.hideModal}
            backdrop="static"
            keyboard={false}
            centered
            scrollable={true}
          >

            {!loading &&
              <Modal.Header >
                <strong>
                  10 อันดับ {title}
                  <br />
                  ประจำวันที่ {date}
                </strong>
              </Modal.Header>
            }
            <Modal.Body >

              {loading ? (
                <div className="text-center p-4">
                  <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                </div>
              ) : (
                <Table striped hover className="table-sm">
                  <thead>
                    <tr className="text-center">
                      <th>อันดับ</th>
                      <th >รหัสผู้ใช้</th>
                      <th>จำนวนเงินที่ชนะ</th>
                      {have_percent && <th>% ได้คืน</th>}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {rows.map((row, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{row.username}</td>
                        <td>{row.amount}</td>
                        {have_percent && <td>{row.percentage}</td>}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Modal.Body>

            {!notClose &&
              <Modal.Footer className="text-center">
                <Button variant="secondary" block disabled={loading} onClick={this.hideModal}>ปิด</Button>
              </Modal.Footer>
            }
          </Modal>

        )}

      </>
    )
  }
}


export default TopList