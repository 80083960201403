import React from 'react';
import { Row, Col, Card, Button, Alert, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/main.css'


import request from '../libs/request';
import utility from '../libs/utility';

import Layout from '../component/layout/member';
import TopComponent from '../component/top';
import Depositx2MainComponent from './promotion/depositx2/main';
import Depositx2EnrollComponent from './promotion/depositx2/enroll';
import CashbackConfirmComponent from './promotion/cashback/confirm';

import BankLogo from '../component/bank_logo'

import CONST from '../constant'


//import config from '../config';
//const CONFIG = config[ process.env.NODE_ENV || 'development' ];

/*
const MIRROR_URL = [
  'http://ufa6666.com', 'http://ufa7777.com', 'http://ufa9999.com', 'http://uf99999.com', 'http://kingbeer.net'
]
*/

const ALLOW_BANK_ADD = (process.env.REACT_APP_BANK_ADD && process.env.REACT_APP_BANK_ADD === 'true') || false



const cookies = new Cookies();

class Main extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);
    let system = cookies.get(process.env.REACT_APP_COOKIE_SYSTEM);

    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get(process.env.REACT_APP_COOKIE_DOWNLINE)) cookies.remove(process.env.REACT_APP_COOKIE_DOWNLINE);
        cookies.set(process.env.REACT_APP_COOKIE_DOWNLINE, { code: refer });
      }
    }

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      //password: data?(CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8):'',
      //password: 'Pwd03205',
      credit: 0,
      point: 0,
      goDeposit: false,
      loadingUFABET: false,
      //ufabet_fields: [],
      system,
      is_joker: (system && system.domain === 'joker'),
      mirror: [],
      showChangePassword: false,
      password_old: '',
      password_new: '',
      intro: null,
      games: [],
      game: null,
      goGame: null,
      goGameType: null,
      go: null,
      goPlay: false,
      pathname: null,
      topType: null,
      use_point: false,
      haveCashback: false,
      depositx2: null,
      enrollDepositx2: false,
      cashback_data: null,
      showCashbackConfirm: false,
      mode: 'normal',
      changeBank: false,
      member: { accounts: [] },
      showEditBank: false,
      banks: [],
      banks_list: [],
      bank_account: {},
      bank_form: {},
    }

    //console.log('password', (CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8) )

    this.loginUfabet = React.createRef();

    this.goDeposit = this.goDeposit.bind(this);
    this.enterGameSite = this.enterGameSite.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.setShowChangePassword = this.setShowChangePassword.bind(this);
    this.savePassword = this.savePassword.bind(this);
    this.changePasswordValue = this.changePasswordValue.bind(this);
    this.playGame = this.playGame.bind(this);
    this.closeGame = this.closeGame.bind(this);
    this.goCashback = this.goCashback.bind(this);
    this.goProfile = this.goProfile.bind(this);
    this.viewTop = this.viewTop.bind(this);
    this.closeTop = this.closeTop.bind(this);
    this.enrollDepositx2 = this.enrollDepositx2.bind(this);
    this.hideDepositx2 = this.hideDepositx2.bind(this);
    this.switchMode = this.switchMode.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getCashback = this.getCashback.bind(this);
    this.hideCashbackConfirm = this.hideCashbackConfirm.bind(this);
    this.editBank = this.editBank.bind(this);
    this.saveBank = this.saveBank.bind(this);
    this.setBankValue = this.setBankValue.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(redirect = null) {

    this.setState({ loading: true }, () => {

      request.get('page/main').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              let data = {
                credit: result.data.credit,
                point: result.data.point || 0,
                mirror: result.data.mirror_site,
                intro: result.data.intro,
                games: result.data.games,
                use_point: result.data.use_point || false,
                haveCashback: result.data.haveCashback || false,
                cashback_data: result.data.cashback_data,
                depositx2: result.data.depositx2,
                banks: result.data.banks,
              };

              if (result.data.feature) {
                data.changeBank = result.data.feature.changeBank || false;
              }

              if (result.data.member && result.data.member.accounts) data.member = result.data.member


              this.setState(data, () => {
                this.props.dispatch({ type: 'CREDIT', value: this.state.credit })

                if (redirect) {
                  if (redirect === 'depositx2') this.switchMode(redirect)
                }
              });

            }
            else {
              if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
              else alert(result.message);

            }

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }

  goDeposit() {
    this.setState({ go: 'deposit' });
  }

  enterGameSite() {

    this.setState({ go: 'play' });
  }

  changePassword() {
    if (this.state.is_joker) {
      this.setShowChangePassword();
    }
  }

  setShowChangePassword(show = true) {
    this.setState({ showChangePassword: show })
  }

  savePassword(e) {
    e.preventDefault();

    this.setState({ loading: true }, () => {

      request.post('member/password', this.state).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              alert('เปลี่ยนรหัสผ่านเรียบร้อยแล้ว')

              this.setShowChangePassword(false);
            }
            else {
              alert(result.message);
            }
          });

        }
        else {
          console.log('Cannot register');
          this.setState({ loading: false });
        }
      })

    })

    //this.setShowChangePassword(false);
    //console.log('savePassword')
  }

  changePasswordValue(e) {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value || '' })
  }

  switchMode(mode) {
    this.setState({ mode })
  }

  playGame(game, type = null) {

    this.setState({ loading: true, goGameType: null }, () => {
      let url = 'game/' + game.type

      request.get(url).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              let data = { goGame: game.type }
              if (type) data.goGameType = type;

              this.setState(data)

            }
            else {
              alert(result.message);
            }
          });

        }
        else {
          console.log('Cannot register');
          this.setState({ loading: false });
        }
      })

    })

  }

  closeGame() {
    this.setState({ game: null }, () => {
    })
  }


  goCashback() {
    this.setState({ go: 'cashback' })
  }


  goProfile() {

    this.setState({ go: 'profile' });
  }

  viewTop(type) {
    this.setState({ topType: type })
  }

  closeTop() {
    this.setState({ topType: null })
  }

  enrollDepositx2() {
    if (this.state.depositx2 && this.state.depositx2.open && this.state.depositx2.promotion && this.state.depositx2.promotion.id) {

      this.setState({ enrollDepositx2: true })

    }
  }

  hideDepositx2(reload = false) {
    this.setState({ enrollDepositx2: false }, () => {

      this.loadData(reload ? 'depositx2' : null);


      if (reload) {
        //window.location.reload();

      }

    })
  }

  getCashback() {
    if (this.state.cashback_data) {
      this.setState({ showCashbackConfirm: true })
    }
  }

  hideCashbackConfirm() {

    this.setState({ showCashbackConfirm: false })
  }

  editBank(account, add = false) {
    //add = false
    //const accounts = this.state.member.accounts
    let banks_list = []

    if (account.type === 'truewallet') {
      banks_list.push({ value: 'truewallet', name: 'True Wallet' })
    } else {
      for (const bank of this.state.banks) {
        banks_list.push({ value: bank.id, name: bank.name })
      }
    }

    let bank_account = {
      bank: account.bank,
      no: account.no,
      type: account.type,
      number: account.number,
      number2: account.number2,
    }

    let bank_form = {
      bank: account?.bank?.id || '',
      no: account.no,
      number: account.number || '',
      number2: account.number2 || '',
    }

    if (this.state.changeBank || (add && ALLOW_BANK_ADD)) {
      this.setState({ showEditBank: true, banks_list, bank_account, bank_form })
    }
  }

  setBankValue(field, value) {
    let bank_form = { ...this.state.bank_form }
    bank_form[field] = value

    this.setState({ bank_form })
  }

  saveBank(e) {
    e.preventDefault()



    this.setState({ loading: true }, () => {

      request.post('member/bank', this.state.bank_form).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              alert('แก้ไขบัญชีเรียบร้อยแล้ว')

              window.location.reload();
              //this.setState({ showEditBank: false })
            }
            else {
              alert(result.message);
            }
          });

        }
        else {
          console.log('ไม่สามารถแก้ไขข้อมูลได้ขณะนี้');
          this.setState({ loading: false });
        }
      })

    })


  }


  render() {
    const { loading, loadingUFABET, logined, username, credit, point, system, is_joker, showChangePassword, password_new, password_old, intro, games, goGame, go } = this.state;

    const { mode, topType, use_point, goGameType, haveCashback, depositx2, enrollDepositx2, showCashbackConfirm, cashback_data, changeBank } = this.state;

    const { member, showEditBank, banks_list, bank_account } = this.state;

    let go_game = null;
    if (goGame) {
      go_game = 'game/' + goGame
      if (goGameType) go_game += '?type=' + goGameType
    }

    const is_normal = (mode === 'normal')

    const bank_default = (bank_account && bank_account.bank) ? bank_account.bank.id : ((banks_list.length > 0) ? banks_list[0].value : '')

    let label_bank_number_1 = (bank_account?.type === 'truewallet') ? 'Wallet ID' : 'เลขที่บัญชี'
    let bank_number_1 = bank_account?.number || ''
    let bank_number_2 = bank_account?.number2 || ''
    if (bank_account?.type === 'truewallet') {
      if (!isNaN(bank_account.number)) {
        bank_number_2 = bank_number_1
        bank_number_1 = ''
      }
    }


    return (
      <>

        <Layout source={utility.getQueryString(this.props, CONST.QUERY_SOURCE)} hideBottom={!is_normal}>
          {logined ? (
            <>
              {(intro && intro.description) &&
                <Row className="justify-content-md-center ">
                  <Col sm={12} lg={6} xs={12} >
                    <Alert variant="warning" >
                      <div dangerouslySetInnerHTML={{ __html: intro.description }}></div>
                    </Alert>
                  </Col>

                </Row>
              }


              {is_normal &&
                <>
                  <Row className="justify-content-md-center ">
                    <Col sm={12} lg={6} xs={12} >

                      <Card className="my-2">
                        <Card.Body>
                          <p>ยินดีต้อนรับ <b>{username}</b></p>

                          <Row>
                            <Col>
                              <b><h4 className="text-success">เครดิตปัจจุบัน</h4> </b>

                              {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                              ) : (
                                <>
                                  {(credit === -999) ? (
                                    <div className="text-danger my-2">ไม่สามารถแสดงรายการได้ขณะนี้</div>
                                  ) : (
                                    <h2 className="my-2">{credit} บาท</h2>
                                  )}
                                </>
                              )}

                            </Col>

                            {use_point &&
                              <Col>
                                <b><h4 className="text-info">คะแนน</h4> </b>

                                {loading ? (
                                  <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                                ) : (
                                  <>
                                    {(point === -999) ? (
                                      <div className="text-danger my-2">ไม่สามารถแสดงรายการได้ขณะนี้</div>
                                    ) : (
                                      <>
                                        <h2 className="my-2">{point} </h2>
                                        <a href={'/point'}>คลิกเพื่อแลกคะแนน</a>
                                      </>
                                    )}
                                  </>
                                )}
                              </Col>
                            }
                          </Row>

                        </Card.Body>
                      </Card>

                    </Col>
                  </Row>

                  <Row className="justify-content-md-center ">

                    <Col sm={12} lg={6} xs={12} >
                      <Row xd={1} md={2} className="justify-content-md-center my-2 mb-4">
                        {member.accounts.map((account, i) => (
                          <Col key={i}>
                            {(account && account.bank && account.bank.id) ? (
                              <Card>
                                <Card.Body className="text-center" onClick={() => this.editBank(account, false)}>
                                  <BankLogo bank={account.bank.id} className="mb-2" />
                                  <div className="text-info">{(account.bank.id !== 'truewallet') ? '****' + account.number.substr(-4) : account.number}</div>

                                  {changeBank &&
                                    <div className="mt-2 text-muted">คลิกเพื่อแก้ไข</div>
                                  }
                                </Card.Body>
                              </Card>
                            ) : (
                              <Card>
                                <Card.Body className="text-center" onClick={() => this.editBank(account, ALLOW_BANK_ADD)}>
                                  <div >
                                    <BankLogo className="mb-4" />
                                    {ALLOW_BANK_ADD &&
                                      <div className="mt-2 text-success">คลิกเพื่อเพิ่มบัญชี</div>
                                    }
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                          </Col>
                        ))}
                      </Row>

                    </Col>
                  </Row>


                  <Row className="justify-content-md-center ">

                    <Col sm={12} lg={6} xs={12} >

                      <Row className="mb-4">
                        <Col sm={12} lg={12} xs={12} className="my-2">
                          <Button variant="primary" size="lg" className="button-flex" block onClick={this.enterGameSite}>
                            {loadingUFABET && <FontAwesomeIcon icon={faSpinner} spin />}  เข้าเล่น {system ? system.domain.toUpperCase() : ''}
                          </Button>
                        </Col>

                        {depositx2 &&
                          <>
                            {(depositx2.open && !depositx2.enroll && depositx2.openEnroll && depositx2.promotion && depositx2.promotion.id) &&
                              <Col sm={12} lg={12} xs={12} className="my-2">
                                <Button variant="primary" size="lg" className="button-flex btn-promotion" block onClick={this.enrollDepositx2}>
                                  สมัครโปรโมชั่นเพิ่ม x 2
                                </Button>
                              </Col>
                            }

                            {(depositx2.open && depositx2.enroll && depositx2.promotion && depositx2.promotion.id && !depositx2.locked) &&
                              <Col sm={12} lg={12} xs={12} className="my-2">
                                <Button variant="primary" size="lg" className="p-3 btn-promotion" block onClick={() => this.switchMode('depositx2')}>
                                  หน้ารายการรับเครดิตเพิ่ม x2 {depositx2.locked}
                                </Button>
                              </Col>
                            }
                          </>
                        }

                        <Col sm={12} lg={12} xs={12} className="my-2">
                          <Button size="lg" variant="success" className="p-3" block onClick={this.goDeposit}>เติมเครดิต</Button>
                        </Col>

                        {is_joker &&
                          <Col sm={12} lg={12} xs={12} className="my-2">
                            <Button size="lg" variant="info" className="p-3" block onClick={this.changePassword}>เปลี่ยนรหัสผ่าน</Button>
                          </Col>
                        }

                        <Col sm={12} lg={12} xs={12} className="my-2 hidden">
                          <Button size="lg" variant="secondary" className="p-3" block onClick={this.goCashback}>Cash Back</Button>
                        </Col>

                        {games.map((game, i) => (
                          <Col sm={12} lg={12} xs={12} className="my-2" key={i}>
                            <Button size="lg" className="p-3 btn-game" block onClick={() => this.playGame(game)}>{game.title}</Button>
                          </Col>
                        ))}

                        {(haveCashback) &&
                          <Col sm={12} lg={12} xs={12} className="my-2">
                            <Button size="lg" className="p-3 btn-cashback" block onClick={this.getCashback}>คืนยอดเสีย</Button>
                          </Col>
                        }

                        <Col sm={12} lg={12} xs={12} className="my-2">
                          <Button size="lg" className="p-3 btn-top-game" block onClick={() => this.viewTop('lucky_wheel')}>10 อันดับ สมาชิกได้รางวัลสูงสุด</Button>
                        </Col>

                        <Col sm={12} lg={12} xs={12} className="my-2" style={{ display: 'none' }}>
                          <Button size="lg" className="p-3 btn-top-cashback" block onClick={() => this.viewTop('cashback')}>10 อันดับ สมาชิกได้ยอดเสียสูงสุด</Button>
                        </Col>

                      </Row>


                    </Col>
                  </Row>
                </>
              }


              {(mode === 'depositx2') &&
                <>
                  <Depositx2MainComponent depositx2={depositx2} switchMode={this.switchMode} />
                </>
              }


              {topType && <TopComponent type={topType} onClose={this.closeTop} />}

              <Modal show={showChangePassword} onHide={() => this.setShowChangePassword(false)}>
                <Form onSubmit={this.savePassword}>
                  <Modal.Header >
                    <Modal.Title>เปลี่ยนรหัสผ่าน</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Alert variant="warning" className="mb-2">กรุณาอย่าเปลี่ยนรหัสผ่านที่ระบบ Joker โดยตรง</Alert>

                    <Form.Group className="mb-4" controlId="password_old">
                      <Form.Label>รหัสผ่านเดิม</Form.Label>
                      <Form.Control size="lg" type="password" placeholder="กรอกรหัสผ่านเดิม" value={password_old} onChange={this.changePasswordValue} required />
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="password_new">
                      <Form.Label>รหัสผ่านใหม่</Form.Label>
                      <Form.Control size="lg" type="password" placeholder="กรอกรหัสผ่านใหม่" title="รหัสผ่านจะต้องมีความยาวไม่น้อยกว่า 4 ตัวอักษร" value={password_new} onChange={this.changePasswordValue} minLength={4} required />
                      <Form.Text className="text-danger">รหัสผ่านจะต้องมีความยาวไม่น้อยกว่า 4 ตัวอักษร</Form.Text>
                    </Form.Group>

                  </Modal.Body>
                  <Modal.Footer>
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                    ) : (
                      <>
                        <Button variant="outline-secondary" type="button" onClick={() => this.setShowChangePassword(false)}>ยกเลิก</Button>
                        <Button variant="primary" type="submit" className="px-4">บันทึก</Button>
                      </>
                    )}
                  </Modal.Footer>
                </Form>
              </Modal>



              <Modal show={showEditBank} onHide={() => this.setState({ showEditBank: false })} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form onSubmit={this.saveBank}>
                  <Modal.Header closeButton={!loading}>
                    <Modal.Title>แก้ไขบัญชี</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="frmbank.bank">
                      <Form.Label>ธนาคาร</Form.Label>
                      <Form.Control as="select" size="lg" defaultValue={bank_default} onChange={(e) => this.setBankValue('bank', e.target.value)}>
                        {banks_list.map((bank, i) => (
                          <option key={i} value={bank.value}>{bank.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="frmbank.number">
                      <Form.Label>{label_bank_number_1}</Form.Label>
                      <Form.Control type="text" size="lg" defaultValue={bank_number_1} minLength={10} maxLength={(bank_account?.type === 'truewallet') ? 10 : 20} required={!(bank_account?.type === 'truewallet')} onChange={(e) => this.setBankValue('number', e.target.value)} />
                    </Form.Group>

                    {(bank_account?.type === 'truewallet') &&
                      <Form.Group className="mb-3" controlId="frmbank.number2">
                        <Form.Label>เบอร์มือถือ</Form.Label>
                        <Form.Control type="text" size="lg" defaultValue={bank_number_2} minLength={10} maxLength={(bank_account?.type === 'truewallet') ? 10 : 20} required onChange={(e) => this.setBankValue('number2', e.target.value)} />
                      </Form.Group>
                    }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="outline-secondary" disabled={loading} onClick={() => loading ? null : this.setState({ showEditBank: false })}>ยกเลิก</Button>
                    <Button variant="primary" type="submit" className="px-4" disabled={loading}>บันทึก</Button>
                  </Modal.Footer>
                </Form>
              </Modal>


              {(enrollDepositx2 && depositx2) && <Depositx2EnrollComponent data={depositx2} onFinish={this.hideDepositx2} />}

              {showCashbackConfirm && <CashbackConfirmComponent cashback={cashback_data} onFinish={this.hideCashbackConfirm} />}

            </>
          ) : (<Redirect to="/signin" />)}


          {go_game && <Redirect to={go_game} />}
          {go && <>{(go === 'cashback') ? (<Redirect to="/promotion/cashback" />) : (<Redirect to={'/' + go} />)}</>}
        </Layout>

      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Main);