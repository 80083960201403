import React from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import '../../../assets/css/main.css'

import request from '../../../libs/request';
import Layout from '../../../component/layout/member';

const cookies = new Cookies();

class Cashback extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      logs: [],
      showModal: false,
      intro: null,
    }

    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {

    this.setState({ loading: true }, () => {

      request.get('page/promotion/cashback').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;
          console.log(result)

          this.setState({ loading: false }, () => {

            if (result.success) {

              let data = {
                logs: result.data.logs,
              }


              this.setState(data);

            }
            else alert(result.message);

          });

        }
        else {
          console.log('Cannot call');
        }
      })

    })
  }


  hideModal() {
    this.setState({ showModal: false }, () => {
    });
  }


  render() {
    const { loading, logined, logs } = this.state;


    return (
      <>


        {logined ? (
          <>


            <Layout>
              <Row className="justify-content-md-center ">
                <Col sm={12} lg={6} xs={12} >
                  <h3 className="h">Cash Back</h3>

                  <Card className="my-4">
                    <Card.Body>

                      {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (

                        <>

                          {(logs.length > 0) ? (

                            <div className="table-responsive">
                              <Table striped hover className="table-sm table-small">
                                <thead>
                                  <tr className="text-center">
                                    <th>รอบการคืนยอดเสีย</th>
                                    <th >ยอดเติมรวม</th>
                                    <th>ยอดถอนรวม</th>
                                    <th>ยอดเสียรวม</th>
                                    <th>เครดิตเงินคืน</th>
                                    <th >สถานะ</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {logs.map((log, i) => (
                                    <tr key={i} className="text-center">
                                      <td>{log.start}<br />ถึง<br />{log.end}</td>
                                      <td>{log.deposit}</td>
                                      <td>{log.withdraw}</td>
                                      <td>{log.summary}</td>
                                      <td>{log.cashback}</td>
                                      <td>{log.status}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            <div className="text-center">
                              <h5 className="text-secondary">ไม่พบข้อมูล Cash Back</h5>
                            </div>
                          )}
                        </>

                      )}

                    </Card.Body>
                  </Card>

                </Col>
              </Row>

            </Layout>


          </>
        ) : (<Redirect to="/signin" />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Cashback);