import React from 'react';
//import tawkTo from "tawkto-react";
import { connect } from 'react-redux';


class Chat extends React.Component {

  componentDidMount() {

    if (process.env.REACT_APP_TAWKTO_PROPERTYID && process.env.REACT_APP_TAWKTO_KEY) {
      // tawkTo(process.env.REACT_APP_TAWKTO_PROPERTYID, process.env.REACT_APP_TAWKTO_KEY)
    }
  }

  render() {
    return (
      <>
      </>
    )
  }
}
const mapStateToProps = state => ({
  loading: state.loading,
});


export default connect(mapStateToProps)(Chat);