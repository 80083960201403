import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/layout.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';


switch (process.env.REACT_APP_CODE) {

  case 'lenball':
    import('./assets/css/theme/lenball.css').then((o) => { })
    break;
  case 'vth888':
    import('./assets/css/theme/vth888.css').then((o) => { })
    break;
  case 'ufasystem':
    import('./assets/css/theme/ufasystem.css').then((o) => { })
    break;

  default:
    break;
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
