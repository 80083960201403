import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import Iframe from 'react-iframe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { random } from 'lodash'

import Lottie from 'lottie-react-web'
import LottieWin1 from '../../assets/lottie/win-1.json'
import LottieWin2 from '../../assets/lottie/win-2.json'
import LottieWin3 from '../../assets/lottie/win-3.json'
import LottieWin4 from '../../assets/lottie/win-4.json'
import LottieLost from '../../assets/lottie/lost.json'

import request from '../../libs/request';

const cookies = new Cookies();

class GameLuckyWheel extends React.Component {
  constructor(props) {
    super(props)

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);


    this.state = {
      loading: false,
      logined: data ? true : false,
      show: true,
      totalPlay: 0,
      game: null,
      play: false,
      url: null,
      play_type: null,
      showResult: false,
      earn: 0,
      quota: null,
      message: {
        intro: '',
        empty: '',
      },
      lotties: {
        win: [LottieWin1, LottieWin2, LottieWin3, LottieWin4],
        lost: [LottieLost],
      },
      gameType: null,
      logPlay: null,
    }

    this.start = this.start.bind(this)
    this.close = this.close.bind(this)
    this.playGame = this.playGame.bind(this)
    this.submitResult = this.submitResult.bind(this)
  }


  componentDidMount() {

    this.setState({ loading: true }, () => {
      let Self = this; //To avoid scope and cache
      function receiveMessageFromIndex(event) {
        if (event !== undefined && event.data && typeof event.data === 'string' && event.data.indexOf('lucky_wheel@@@') !== -1) {
          let code = JSON.parse(event.data.split('@@@').pop())

          Self.submitResult(code)
        }
      }

      window.addEventListener("message", receiveMessageFromIndex, false);

      this.start();
    })

  }

  start() {

    this.setState({ loading: true, showResult: false, earn: 0, play: false, gameType: this.props.type || null }, () => {

      let url = 'game/lucky_wheel/start'
      if (this.props.type) url += '?type=' + this.props.type

      request.get(url).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;
          this.setState({ loading: false }, () => {
            if (result.success) {

              let { game, totalPlay, quota, message, log: logPlay } = result.data;

              this.setState({ game, totalPlay, quota, message, logPlay }, () => {

                if (this.state.totalPlay > 0) {
                }
                else {
                  //if (this.close) this.close();
                }


              })
            }
            else {
              if (result.message.indexOf('permission') !== -1) {
                this.close()
              }
              else
                alert(result.message);
            }
          });

        }
        else {
          console.log('Cannot access');
          this.setState({ loading: false });
        }
      }).catch(error => {
        alert('ไม่สามารถเข้าเล่นได้ขณะนี้')
        this.setState({ loading: false })
      })
    })
  }

  playGame() {
    this.setState({ loading: true }, () => {

      request.get('game/lucky_wheel/play').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;
          this.setState({ loading: false }, () => {
            if (result.success) {

              let { url, totalPlay, play_type } = result.data;

              this.setState({ totalPlay }, () => {

                if (this.state.totalPlay > 0) {
                  let this_data = { url, play: true }
                  if (play_type) this_data.play_type = play_type

                  //console.log(this_data)

                  this.setState(this_data)
                }
                else {
                  if (this.close) this.close();
                }

              })
            }
            else {
              if (result.message.indexOf('permission') !== -1) {
                this.close()
              }
              else
                alert(result.message);
            }
          });

        }
        else {
          console.log('Cannot access');
          this.setState({ loading: false });
        }
      }).catch(error => {
        alert('ไม่สามารถเข้าเล่นได้ขณะนี้')
        this.setState({ loading: false })
      })

    })
  }

  submitResult(code) {
    this.setState({ loading: true, play: false }, () => {

      let data = code;

      request.post('game/lucky_wheel/play', data).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;
          this.setState({ loading: false }, () => {
            if (result.success) {
              this.setState({ showResult: true, earn: code.value })
            }
            else {
              if (result.message.indexOf('permission') !== -1) {
                this.close()
              }
              else
                alert(result.message);
            }
          });

        }
        else {
          console.log('Cannot access');
          this.setState({ loading: false });
        }
      }).catch(error => {
        alert('ไม่สามารถเข้าเล่นได้ขณะนี้')
        this.setState({ loading: false })
      })
    })

  }

  close() {
    if (this.props.onHide) this.props.onHide();
  }

  render() {
    const { loading, show, totalPlay, game, play, url, play_type, showResult, earn, message, lotties, gameType, logPlay } = this.state;

    const canPlay = (totalPlay > 0)

    let lottieFile = null;
    if (showResult) {
      let lottie_type = (earn > 0) ? 'win' : 'lost'
      lottieFile = lotties[lottie_type][random(0, lotties[lottie_type].length - 1)];
    }

    const isCashback = ((gameType && gameType === 'cashback') || (play_type && play_type === 'cashback'))

    return (
      <>
        {game &&
          <Modal show={show} onHide={this.close} size="xl" centered keyboard={false} backdrop={'static'} >

            {loading ? (
              <div className="text-center mt-4"><FontAwesomeIcon icon={faSpinner} spin size="lg" /></div>
            ) : (
              <>
                <Modal.Header closeButton={!loading} >
                  <Modal.Title>
                    {play ? (<>เล่นได้ <strong className="text-success">{totalPlay}</strong> ครั้ง</>) : game.title}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: play ? 0 : 20, height: '80vh', maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                  {play ? (
                    <Iframe
                      url={url}
                      width="100%"
                      height="100%"
                      id="myId"
                      className="myClassname"
                      display="initial"
                      position="relative"
                      allowFullScreen
                    />

                  ) : (
                    <>
                      {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                      ) : (
                        <div className=" mt-4">

                          {showResult ? (
                            <div className="text-center">
                              {lottieFile &&
                                <Lottie
                                  options={{
                                    animationData: lottieFile,
                                  }}
                                  height={200}
                                  className="mb-2"
                                />
                              }
                              {(earn > 0) ? (
                                <>
                                  <h4><p>
                                    คุณได้รับรางวัล

                                    {play_type &&
                                      <>{isCashback && <span className="ml-1">Cash Back</span>}</>
                                    }
                                  </p> <p><strong className="text-success">{earn}</strong></p> <p>บาท</p></h4>
                                  <div className="my-4">
                                    <Button variant="success" size="lg" block onClick={this.start}>ปิด</Button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <h4 className="text-danger">
                                    เสียใจด้วย คุณไม่ได้รับรางวัล
                                    {play_type &&
                                      <>{isCashback && <span className="ml-1">Cash Back</span>}</>
                                    }
                                  </h4>
                                  <div className="my-4">
                                    <Button variant="secondary" size="lg" block onClick={this.start}>ปิด</Button>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <>
                              {canPlay ? (
                                <>
                                  {(message && message.intro) ? (<div dangerouslySetInnerHTML={{ __html: message.intro }}></div>) : ''}
                                  <div className="text-center">
                                    <h5> คุณสามารถเล่นได้อีก <strong className="text-success">{totalPlay}</strong> ครั้ง</h5>
                                    <div className="my-4">
                                      <Button variant="primary" size="lg" block onClick={this.playGame} className={isCashback ? 'btn-cashback' : ''}>
                                        เข้าเล่น

                                        {isCashback && ' Cash Back'}
                                      </Button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>

                                  {(message && message.empty) ? (
                                    <div dangerouslySetInnerHTML={{ __html: message.empty }}></div>
                                  ) : (

                                    <>
                                      {logPlay ? (
                                        <div className="text-center">

                                          <h5>คุณได้เล่น  {isCashback && ' Cash Back'}</h5>
                                          <h5>เมื่อวันที่ {logPlay.playedAt}</h5>

                                          <h5>
                                            {(logPlay.amount > 0) ? (
                                              <>
                                                ได้รับเครดิต <span className="text-success">{logPlay.amount}</span> บาท
                                              </>
                                            ) : (
                                              <span className="text-warning">ไม่ได้รับรางวัล</span>
                                            )}
                                          </h5>

                                        </div>
                                      ) : (
                                        <div className="text-center">กรุณาอ่านเงื่อนไขการรับสิทธิ์หมุนกงล้อ ที่ <a href="https://www.lenball.com" rel="noopener noreferrer" target="_blank">https://www.lenball.com</a> </div>
                                      )}

                                    </>

                                  )}


                                </>
                              )}
                            </>
                          )}

                        </div>
                      )}
                    </>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" block onClick={this.close}>ปิด</Button>
                </Modal.Footer>
              </>
            )}

          </Modal>
        }
      </>
    )
  }

}

const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(GameLuckyWheel);