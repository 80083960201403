import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/main.css'

import utility from '../libs/utility';
import request from '../libs/request';
import Layout from '../component/layout/member';
import Intro from '../component/intro';


import CONST from '../constant'


const cookies = new Cookies();


class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      member: {},
      mode: 'normal',
      intro: null,
      inputNumber: '',
      allowChange: false,
      allowSave: false,
    }

    this.save = this.save.bind(this);
    this.enterWalletID = this.enterWalletID.bind(this);
    this.loadProfile = this.loadProfile.bind(this);
  }

  componentDidMount() {
    this.loadProfile();
  }


  loadProfile() {

    this.setState({ loading: true, pathname: this.props.location.pathname }, () => {

      request.get('page/profile').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              const { member, intro, feature } = result.data

              let data = {
                member,
                intro,
              }

              if (feature) {
                data.allowChange = feature.changeTruewallet || false;
              }

              this.setState(data);


            }
            else {

              if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
              else alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot load data');
        }
      })

    })
  }

  enterWalletID(e) {
    let ok = false;
    let phone = e.currentTarget.value || 0;
    if (phone && this.state.allowChange) {
      phone += '';

      const { member } = this.state


      ok = (phone.toLowerCase() !== member.account.number.toLowerCase());
    }

    this.setState({ allowSave: ok }, () => {
      if (ok) this.setState({ inputNumber: phone })
    })

  }


  save() {
    if (this.state.allowSave) {

      this.setState({ loading: true }, () => {

        let data = {
          account: this.state.inputNumber,
        }

        request.post('member/profile', data).then(response => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            this.setState({ loading: false }, () => {

              if (result.success) {
                this.loadProfile();
              }
              else {
                if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
                else alert(result.message);
              }

            });

          }
          else {
            console.log('Cannot load data');
          }
        })

      })

    }
  }

  render() {
    const { loading, logined, intro, mode, allowChange, member, allowSave } = this.state;


    const is_normal = (mode === 'normal')


    return (
      <>


        {logined ? (
          <>

            <Layout source={utility.getQueryString(this.props, CONST.QUERY_SOURCE)} hideBottom={!is_normal}>
              <Row className="justify-content-md-center ">
                <Col sm={12} lg={6} xs={12} >
                  <h3 className="h">แก้ไข True Wallet</h3>
                  <Intro intro={intro} />


                  {(member && member.account) &&
                    <>

                      <Card className="my-4">
                        <Card.Body>

                          <div className="mb-4">
                            บัญชีของคุณ
                            <span className="text-success mx-2">{member.account.bank.name} {member.account.number}</span>
                            (<span className="text-success mx-2">{member.firstname} {member.lastname}</span>)

                          </div>

                          {allowChange &&
                            <>
                              {loading ? (<FontAwesomeIcon icon={faSpinner} size="2x" spin />) : (
                                <>
                                  <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                      <Form.Label>เบอร์ True Wallet / True Wallet ID</Form.Label>
                                      <Form.Control type="text" placeholder="กรอกเบอร์ True Wallet / True Wallet ID" onChange={this.enterWalletID} />
                                      <Form.Text className="text-info">- ถ้าใช้ True Wallet ID กรุณากรอก True Wallet ID</Form.Text>
                                      <Form.Text className="text-info">- ชื่อเจ้าของบัญชี True Wallet จะต้องเป็น <u>{member.firstname} {member.lastname}</u> เท่านั้น</Form.Text>
                                    </Form.Group>
                                    <Button variant="success" type="button" className="pl-4 pr-4 " block onClick={this.save} disabled={!allowSave}>บันทึก</Button>
                                  </Form>


                                </>
                              )}
                            </>
                          }

                        </Card.Body>
                      </Card>

                    </>
                  }


                </Col>


              </Row>



            </Layout>


          </>
        ) : (<Redirect to="/signin" />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(ProfilePage);