import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import '../../../assets/css/main.css'

import request from '../../../libs/request';

const cookies = new Cookies();

class Depositx2 extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      showModal: false,
      intro: null,
      data: {},
    }

    this.enroll = this.enroll.bind(this)
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {

    this.setState({ loading: true }, () => {

      request.get('promotion/depositx2').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;
          console.log(result)

          this.setState({ loading: false }, () => {

            if (result.success) {

              let data = {
                data: result.data,
                showModal: true,
              };


              this.setState(data);

            }
            else {
              alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot call');
        }
      })

    })
  }


  hideModal(reload = false) {
    this.setState({ showModal: false }, () => {

      if (this.props.onFinish) {
        this.props.onFinish(reload);
      }

    });
  }

  enroll() {

    this.setState({ loading: true }, async () => {


      request.post('promotion/depositx2/enroll').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              alert('สมัครโปรโมชั่นเพิ่ม x2 เรียบร้อยแล้ว')


            }
            else {
              alert(result.message);
            }

            this.hideModal(true);
          });

        }
        else {
          console.log('Cannot call');
        }
      })


    })

  }


  render() {
    const { loading, logined, showModal, data } = this.state;



    return (
      <>


        {logined ? (
          <>
            <Modal show={showModal} onHide={() => this.hideModal(false)}>
              <Modal.Header >
                <Modal.Title>โปรโมชั่นเพิ่ม x2</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                {(data && data.open) &&

                  <div dangerouslySetInnerHTML={{ __html: data.promotion.description }}></div>

                }


              </Modal.Body>
              <Modal.Footer>
                {loading ? (
                  <div className="text-center"><FontAwesomeIcon icon={faSpinner} spin size="lg" /></div>
                ) : (
                  <>
                    <Button variant="primary" type="button" className="px-4 button-flex btn-lg btn-block btn-promotion" onClick={this.enroll}>สมัครโปรโมชั่นเพิ่ม x2</Button>
                    <Button variant="outline-secondary btn-block" type="button" onClick={() => this.hideModal(false)}>ยกเลิก</Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>

          </>
        ) : (<Redirect to="/signin" />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Depositx2);