import React from 'react';
import { Form, Alert, Row, Col, Card, Table, InputGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { find } from 'lodash';

import '../assets/css/main.css'

import request from '../libs/request';
import Layout from '../component/layout/member';
import Intro from '../component/intro'

import UtilityLib from '../libs/utility'

const cookies = new Cookies();
//const PIN_TIMEOUT = 5 * 60;
var start_count;

const WITHDRAW_MAX = 0;//10000;
const WITHDRAW_DIVIDER = 10;


class Withdraw extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      logs: [],
      withdraw_min: 100,
      withdraw_max: WITHDRAW_MAX,
      withdraw_limit: WITHDRAW_MAX,
      withdraw_credit: 0,
      withdraw_pin: '',
      withdraw_quota: {},
      withdraw_condition: {},
      withdraw_auto: { active: false, max: 0 },
      withdraw_to: '',
      withdraw_channel: 'truewallet',
      withdraw_remain: 0,
      withdrawid: '',
      counter: 0,
      showWarn: false,
      showConfirm: false,
      showPin: false,
      games: [],
      game: null,
      goGame: null,
      video: null,
      pathname: null,
      intro: null,
      allow: true,
      allowWithdraw: true,
      accounts: [],
    }

    this.hideWarn = this.hideWarn.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.hidePin = this.hidePin.bind(this);
    this.withdrawValue = this.withdrawValue.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.pinValue = this.pinValue.bind(this);
    this.confirmWithdraw = this.confirmWithdraw.bind(this);
    this.confirmPin = this.confirmPin.bind(this);
    this.expirePin = this.expirePin.bind(this);
    this.playGame = this.playGame.bind(this);
    this.closeGame = this.closeGame.bind(this);
    this.changeBank = this.changeBank.bind(this);
    this.updateCondition = this.updateCondition.bind(this);
  }

  componentDidMount() {

    this.loadData();

  }

  loadData() {
    this.setState({ loading: true, pathname: this.props.location.pathname }, () => {

      request.get('page/withdraw').then(response => {
        if (response.data && response.data.status_code === 200) {

          const result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              const system = result.data.system

              //console.log('system', system)

              let data = {
                member: result.data.member,
                system: result.data.system,
                logs: result.data.logs,
                //withdraw_credit: result.data.member.credit || 0,
                withdraw_min: (result.data.withdraw_min < 50) ? 50 : result.data.withdraw_min,
                games: result.data.games,
                intro: result.data.intro,
                allow: (system.configuration && system.configuration.feature) ? system.configuration.feature.withdraw : true,
                withdraw_max: (system.configuration && system.configuration.withdraw) ? system.configuration.withdraw.limit : WITHDRAW_MAX,
                withdraw_limit: (system.configuration && system.configuration.withdraw) ? system.configuration.withdraw.limit : WITHDRAW_MAX,
                accounts: system.accounts || [],
                allowWithdraw: false,
                withdraw_auto: result.data.withdraw_auto,
                withdraw_channel: result.data.withdraw_channel,
              }

              if (typeof result.data.withdraw_allow !== 'undefined') data.allowWithdraw = result.data.withdraw_allow

              if (system.configuration.video && system.configuration.video.withdraw) data.video = system.configuration.video.withdraw

              if (data.withdraw_max > data.withdraw_credit) data.withdraw_max = data.withdraw_credit

              if (result.data.withdraw_quota) {
                data.withdraw_quota = result.data.withdraw_quota

                //console.log('withdraw_max', data.withdraw_max, data.withdraw_limit)
                if (data.allowWithdraw) {
                  for (const c of data.withdraw_quota.conditions) {
                    if (data.withdraw_max > c.remain) data.withdraw_max = c.remain
                    if (c.min > 0 && data.withdraw_min < c.min) data.withdraw_min = c.min
                  }
                }

                //console.log(data.withdraw_max, data.withdraw_quota)
                if (data.withdraw_quota.quota > 0 && data.allowWithdraw && data.withdraw_quota.conditions.length === 0) {
                  //data.withdraw_max = data.withdraw_quota.remain
                  if (data.withdraw_max > data.withdraw_quota.quota) data.withdraw_max = data.withdraw_quota.quota
                  //data.allowWithdraw = (data.withdraw_quota.remain > 0 && data.withdraw_quota.remain > data.withdraw_min)
                } else {
                  // data.allowWithdraw = false
                }
              }

              data.withdraw_remain = data.withdraw_max
              if (data.withdraw_max <= data.member.credit) {
                //data.withdraw_max = data.withdraw_limit
                if (data.withdraw_max > data.member.credit) data.withdraw_max = data.member.credit
              }


              if (data.accounts.length > 0) {
                console.log(data.accounts)
                this.changeBank(data.accounts[0].bank.id + ':' + data.accounts[0].number)
                //data.withdraw_to = data.accounts[0].bank.id + ':' + data.accounts[0].number
              }


              this.setState(data);

            }
            else {

              if (result.message.indexOf('permission') !== -1) this.setState({ logined: false })
              else alert(result.message);
            }

          });

        }
        else {
          console.log('ไม่สามารถอนเงินได้ขณะนี้');
        }
      })

    })
  }

  withdrawValue(e) {
    let credit = e.currentTarget.value || 0;
    this.setState({ withdraw_credit: credit }, () => { this.updateCondition() });
  }


  updateCondition() {

    const { withdraw_credit, withdraw_quota, allowWithdraw } = this.state;

    if (withdraw_credit > 0) {

      //const bankid = withdraw_to.split(':').shift();

      //let type = (withdraw_auto.active && withdraw_auto.max >= withdraw_credit) ? 'auto' : 'manual'

      let withdraw_condition = find(withdraw_quota.conditions, (o) => {

        if (o.min === 0 || (o.min > 0 && o.min <= withdraw_credit)) {

          return { remain: o.remain, quota: o.quota }
        }

        /*
        if (o.type === type && (o.to === bankid || (bankid !== 'truewallet' && o.to === 'bank'))) {
  
          if (o.bank === withdraw_channel || (withdraw_channel !== 'truewallet' && o.bank !== 'truewallet')) {
  
            if (o.min === 0 || (o.min > 0 && o.min <= withdraw_credit)) {
  
              return { remain: o.remain, quota: o.quota }
            }
  
          }
  
        }
        */
      })

      //console.log('withdraw_condition', withdraw_credit, withdraw_condition)

      if (withdraw_condition && allowWithdraw) {


        /*
        if (!withdraw_condition) {
          withdraw_condition = { remain: withdraw_quota.remain, quota: withdraw_quota.quota }
        }
        */


        withdraw_condition.deposit = withdraw_quota.deposit

        let data = {
          withdraw_condition,
        }

        //let isAllowWithdraw = false
        if (withdraw_condition.conditions) {
          for (const c of withdraw_condition.conditions) {
            if (data.withdraw_max < c.remain) {
              data.withdraw_max = c.remain
              //isAllowWithdraw = (c.remain > this.state.withdraw_min)
            }
          }
        }

        /*
        if (withdraw_condition.quota > 0) {
          data.withdraw_max = withdraw_condition.remain
  
          data.allowWithdraw = (withdraw_condition.remain > 0 && withdraw_condition.remain > this.state.withdraw_min)
        } else {
          data.allowWithdraw = false
        }
        */


        //console.log('withdraw_condition', data)

        //this.setState(data)
      }
      else {
        //this.setState({ allowWithdraw: false })
      }


    }


  }


  withdraw() {
    let credit = this.state.withdraw_credit;

    const { withdraw_max, withdraw_min } = this.state

    if (credit > 0 && (withdraw_max === 0 || (withdraw_max > 0 && credit <= withdraw_max)) && credit >= withdraw_min) {


      if (WITHDRAW_DIVIDER > 0) {
        if ((credit % WITHDRAW_DIVIDER) !== 0) {
          let msg = 'จำนวนเงินที่ถอนต้องเป็นจำนวนเต็มสิบ เช่น 10, 30, 510, 1230';

          msg += '\nคุณสามารถถอนด้วยจำนวนเงิน ' + (credit - (credit % WITHDRAW_DIVIDER)) + ' บาท'

          alert(msg);
          return;
        }
      }

      this.setState({ showConfirm: true });
    }
    else {
      this.setState({ showWarn: true })
    }

  }

  hideWarn() {
    this.setState({ showWarn: false });
  }

  hideConfirm() {
    this.setState({ showConfirm: false });
  }

  hidePin() {
    if (start_count) clearInterval(start_count);

    this.setState({ showPin: false }, () => {
      if (this.state.withdrawid && this.state.counter > 0) {
        this.expirePin();
      }
    });
  }

  confirmWithdraw() {

    let credit = this.state.withdraw_credit;

    if (credit > 0 && credit <= this.state.member.credit && !this.state.loading && this.state.allowWithdraw) {


      if (WITHDRAW_DIVIDER > 0) {
        if ((credit % WITHDRAW_DIVIDER) !== 0) {
          alert('จำนวนเงินที่ถอนต้องเป็นจำนวนเต็มสิบ เช่น 10, 30, 510, 1230');
          return;
        }
      }

      this.setState({ loading: true, showConfirm: false, withdrawid: '', counter: 0 }, () => {

        let data = { credit: this.state.withdraw_credit, to: this.state.withdraw_to }

        if (data.credit > this.state.withdraw_remain) data.over = true

        request.post('credit/withdraw', data).then(response => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            setTimeout(() => {

              this.setState({ loading: false }, () => {
                if (result.success) {
                  alert('ทำรายการแจ้งถอนเงินเรียบร้อยแล้ว');
                  this.loadData();
                }
                else alert(result.message);
              });

            }, 1 * 1000);

          }
          else {
            console.log('ไม่สามารถอนเงินได้ขณะนี้');
          }
        })

      })

    }
    else {
      this.setState({ showWarn: true })
    }
  }


  pinValue(e) {
    let pin = e.currentTarget.value || 0;
    this.setState({ withdraw_pin: pin });
  }

  confirmPin() {
    if (this.state.withdrawid) {
      if (start_count) clearInterval(start_count);

      this.setState({ loading: true }, () => {
        request.post('credit/pin/confirm', { id: this.state.withdrawid, pin: this.state.withdraw_pin }).then(response => {

          this.setState({ loading: false, withdrawid: '', counter: 0 }, () => {
            if (response.data && response.data.status_code === 200) {

              let result = response.data;

              if (result.success) {
                this.setState({ withdrawid: '', counter: 0 }, () => {

                  alert('ทำรายการแจ้งถอนเงินเรียบร้อยแล้ว');
                  this.loadData();
                  this.hidePin();

                });

              }
              else alert(result.message);

            }
            else {
              alert('ไม่สามารถอนเงินได้ขณะนี้');
            }
          })
        })
      })
    }
  }

  expirePin() {
    if (this.state.withdrawid) {

      this.setState({ loading: true, showPin: false }, () => {
        request.post('credit/pin/expire', { id: this.state.withdrawid }).then(response => {
          this.setState({ loading: false }, () => {
            this.loadData();
          })
        })
      })
    }
  }

  playGame(game) {

    this.setState({ loading: true }, () => {

      request.get('game/' + game.type).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              this.setState({ goGame: game.type })

            }
            else {
              alert(result.message);
            }
          });

        }
        else {
          console.log('Cannot register');
          this.setState({ loading: false });
        }
      })

    })

  }

  closeGame() {
    this.setState({ game: null }, () => {
    })
  }



  changeBank(bank) {
    console.log(bank)
    this.setState({ withdraw_to: bank }, () => { this.updateCondition() })
  }


  render() {
    const { allow, allowWithdraw, loading, withdraw_min, withdraw_max, logined, username, member, logs, withdraw_credit, withdraw_pin, counter, showWarn, showConfirm, showPin, games, goGame, intro, pathname, withdraw_limit } = this.state;
    const { accounts, withdraw_to, withdraw_condition } = this.state

    // let withdraw_max = WITHDRAW_MAX;
    let withdraw_example = '', withdraw_notallow = 'จำนวนเงินที่ถอน ครบตามที่กำหนดไว้';

    if (withdraw_condition.deposit === 0) withdraw_notallow = 'ยังไม่เคยฝากเงิน'

    //console.log('allowWithdraw', allowWithdraw)

    let allow_withdraw = (member.credit > 0 && allowWithdraw)
    if (allow_withdraw && logs.length > 0) {
      allow_withdraw = (logs[0].status.code !== 'wait')

      /*
      if (member.account && member.credit < WITHDRAW_MAX) {
        withdraw_max = member.credit
        withdraw_example = '';
      }
      */

      if (!allow_withdraw) withdraw_notallow = 'จำนวนครั้งที่ถอนวันนี้ครบตามจำนวนที่กำหนดแล้ว'
      if (!allowWithdraw) withdraw_notallow = ''
    }

    let txtLimitInput = 'ระบุจำนวนเงินที่ต้องการถอน ขั้นต่ำ ' + UtilityLib.formatMoney(withdraw_min, 0) + ' บาท '
    if (withdraw_max > 0) {
      //console.log(withdraw_max, withdraw_limit)
      if (withdraw_max > withdraw_limit) {
        txtLimitInput += ' สูงสุดไม่เกินครั้งละ ' + UtilityLib.formatMoney(withdraw_limit, 0) + ' บาท'

        if (withdraw_limit > 0) {
          const example_credit = withdraw_limit * 1.5

          withdraw_example = 'เช่น ถ้าจะถอน ' + UtilityLib.formatMoney(example_credit, 0) + ' ให้ถอน ' + UtilityLib.formatMoney(withdraw_limit, 0) + ' และ ' + UtilityLib.formatMoney(example_credit - withdraw_limit, 0) + ' บาท '
        }
      }
      else {
        txtLimitInput += ' สูงสุดไม่เกิน ' + UtilityLib.formatMoney(withdraw_max, 0) + ' บาท'
      }
    }


    let member_account = member.account
    if (withdraw_to) {
      const [bank_id, bank_number] = withdraw_to.split(':')

      let account = find(accounts, { bank: { id: bank_id }, number: bank_number })
      if (account) member_account = account
    }

    //console.log('allowWithdraw', allowWithdraw)

    return (
      <>


        {logined ? (
          <>

            {member.account &&
              <>

                <Layout path={pathname}>
                  <Row className="justify-content-md-center ">
                    <Col sm={12} lg={6} xs={12} >
                      <h3 className="h">ถอนเงิน</h3>
                      <Card className="my-4">
                        <Card.Body>
                          ยินดีต้อนรับ <b>{username}</b>
                          <br />
                          <div className="mb-2">ยอดเครดิต {member.credit} บาท</div>
                        </Card.Body>
                      </Card>

                      <Intro intro={intro} />

                      <Card>
                        <Card.Body>

                          {(allowWithdraw && allow) &&
                            <>
                              {loading ? (<FontAwesomeIcon icon={faSpinner} size="2x" spin />) : (
                                <>

                                  {(accounts.length > 0 &&
                                    <Form.Group controlId="register_bank" size="lg">
                                      <Form.Label><b>โอนเข้า</b></Form.Label>

                                      {(accounts.length > 1) ? (
                                        <Form.Control as="select" size="lg" value={withdraw_to} onChange={(e) => this.changeBank(e.target.value)} title="กรุณาเลือกธนาคาร">
                                          {accounts.map((account, i) => <option key={i} value={account.bank.id + ':' + account.number}>{account.bank.name} {account.number}</option>)}
                                        </Form.Control>
                                      ) : (
                                        <h5 className="text-primary">{accounts[0].bank.name} {accounts[0].number}</h5>
                                      )}

                                    </Form.Group>
                                  )}

                                  <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>จำนวนเงิน</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="number"
                                      placeholder={txtLimitInput}
                                      max={withdraw_max}
                                      min={withdraw_min}
                                      onChange={this.withdrawValue}
                                    />
                                    <InputGroup.Append>
                                      <Button variant="success" onClick={this.withdraw} disabled={!allow_withdraw}>ถอนเงิน</Button>
                                    </InputGroup.Append>
                                  </InputGroup>

                                  <Form.Text muted>{txtLimitInput}</Form.Text>

                                  {withdraw_example &&
                                    <Form.Text muted>{withdraw_example}</Form.Text>
                                  }

                                  {WITHDRAW_DIVIDER > 0 &&
                                    <Form.Text muted className="text-info">จำนวนเงินที่ถอนต้องเป็นจำนวนเต็มสิบ เช่น 10, 30, 510, 1230</Form.Text>
                                  }

                                </>
                              )}
                            </>
                          }

                          {!allowWithdraw && <Alert variant="warning">{withdraw_notallow}</Alert>}

                        </Card.Body>
                      </Card>

                      {games.length > 0 &&
                        <div className="text-center">
                          {games.map((game, i) => (
                            <div className="my-2" key={i}>
                              <Button variant="danger" onClick={() => this.playGame(game)}>{game.title}</Button>
                            </div>
                          ))
                          }
                        </div>
                      }


                      <Card className="my-4">
                        <Card.Body>
                          <div className="mb-2">
                            <b>รายการล่าสุด</b>
                          </div>

                          {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (

                            <Table striped hover responsive className="table-sm">
                              <thead>
                                <tr className="text-center">
                                  <th>วันที่</th>
                                  <th>จำนวนเงิน</th>
                                  <th>โอนเข้า</th>
                                  <th width="120">สถานะ</th>
                                  <th >หมายเหตุ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {logs.map((log, i) => (
                                  <tr key={i} className="text-center">
                                    <td><small>{log.createdAt}</small></td>
                                    <td>
                                      {log.credit} บาท
                                    </td>
                                    <td>
                                      <small >{log?.target?.bank?.name} {log?.target?.account}</small>
                                    </td>
                                    <td><span className={'text-' + log.status.mode}>{log.status.title}</span></td>
                                    <td><small>{log.remark}</small></td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                          )}

                        </Card.Body>
                      </Card>

                    </Col>
                  </Row>
                </Layout>

                <Modal
                  show={showWarn}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hideWarn}
                  centered
                >
                  <Modal.Body className="text-center">

                    <FontAwesomeIcon className="mb-4 text-danger" icon={faExclamationTriangle} size="6x" />

                    <h4 className="text-info mb-4">จำนวนเครดิตไม่ถูกต้อง</h4>
                    <h4 className="text-warning mb-4">( จะต้องมากกว่า {withdraw_min}

                      {(withdraw_max > 0) && <span>และไม่เกิน {UtilityLib.formatMoney(withdraw_max, 0)} บาท</span>}
                      )
                    </h4>

                    <div className="text-center">

                      <Button variant="secondary" className="px-4" onClick={this.hideWarn}>ปิด</Button>
                    </div>
                  </Modal.Body>
                </Modal>


                <Modal
                  show={showConfirm}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hideConfirm}
                  centered
                >
                  <Modal.Body className="text-center">

                    <h4 className="text-info mb-4">ยืนยันการถอนเงิน</h4>
                    <h4 className=" mb-4">ถอนเงิน <span className="text-info">{withdraw_credit}</span> บาท</h4>
                    <h4 className=" mb-4">เข้าบัญชี  </h4>
                    <h4 className="mb-4">
                      <span className="text-success">
                        {member_account.bank.name} {member_account.number}
                      </span>
                    </h4>
                    {(member_account.bank.id !== 'truewallet') && <h4 className="mb-4">(<span className="text-success">{member.firstname} {member.lastname}</span>)</h4>}

                    <div className="text-center">

                      <Button variant="outline-secondary" className="mr-4" onClick={this.hideConfirm}>ยกเลิก</Button>
                      <Button variant="primary" className="px-4" onClick={this.confirmWithdraw}>ยืนยัน</Button>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showPin}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hidePin}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Body className="text-center">

                    <div className="mb-4">
                      <h4 >กรุณากรอก รหัสยืนยัน 6 หลัก จาก SMS</h4>
                    </div>

                    <FormControl
                      className="mb-4 text-center"
                      type="text"
                      size="lg"
                      placeholder=""
                      value={withdraw_pin}
                      onChange={this.pinValue}
                      maxLength={6}
                      minLength={6}
                    />

                    <div className="mb-4">
                      <h5 className="text-warning">รหัสยืนยันจะหมดอายุภายใน {counter}</h5>
                    </div>

                    {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (
                      <div className="text-center">

                        <Button variant="outline-secondary" className="mr-4" onClick={this.hidePin}>ยกเลิก</Button>
                        <Button variant="primary" className="px-4" onClick={this.confirmPin}>ยืนยัน</Button>

                      </div>
                    )}

                  </Modal.Body>
                </Modal>
              </>
            }

          </>
        ) : (<Redirect to={'/signin?path=' + pathname} />)}

        {goGame && <Redirect to={'/game/' + goGame + '/withdraw'} />}


      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading,
  credit: state.credit,
});

export default connect(mapStateToProps)(Withdraw);