import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import '../../../assets/css/main.css'

import request from '../../../libs/request';

import WithdrawComponent from './withdraw'

const cookies = new Cookies();

class Depositx2 extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: true,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      intro: null,
      credit: 0,
      creditRaw: 0,
      creditAllow: 0,
      withdrawMin: 0,
      expire: null,
      loadingUFABET: false,
      allowWithdraw: false,
      showWithdraw: false,
      promotion: null,
      depositx2: null,
      isExpire: false,
    }

    this.enroll = this.enroll.bind(this)
    this.hideModal = this.hideModal.bind(this);
    this.switchMode = this.switchMode.bind(this);
    this.enterGameSite = this.enterGameSite.bind(this);
    this.showWithdraw = this.showWithdraw.bind(this);
  }

  componentDidMount() {

    this.setState({ loading: true, depositx2: this.props.depositx2, promotion: this.props.depositx2.promotion }, () => {

      request.get('page/depositx2').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              const { username, credit, expire, withdrawMin, allowWithdraw, creditRaw, creditAllow, isExpire } = result.data

              let data = {
                username,
                credit,
                expire,
                withdrawMin,
                allowWithdraw,
                creditRaw,
                creditAllow,
                isExpire,
              };


              this.setState(data);

            }
            else {
              alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot call');
        }
      })

    })
  }


  hideModal(reload = false) {
    this.setState({ showModal: false }, () => {

      if (this.props.onFinish) {
        this.props.onFinish(reload);
      }

    });
  }

  enroll() {

    this.setState({ loading: true }, async () => {


      request.post('promotion/depositx2/enroll').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              alert('สมัครโปรโมชั่นเพิ่ม x2 เรียบร้อยแล้ว')


            }
            else {
              alert(result.message);
            }

            this.hideModal(true);
          });

        }
        else {
          console.log('Cannot call');
        }
      })


    })

  }


  enterGameSite() {
    this.setState({ loading: true }, () => {

      request.post('ufabet/access/depositx2', {}).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {
            let redirect_url = null

            if (result.success) {

              if (result.data.url) {
                redirect_url = result.data.url

                //window.location.href = result.data.url;
              }
              else {
                //window.location.href(this_url);
              }



            }
            else {
              console.log(result.message);
              //window.location.href(this_url);
            }

            if (redirect_url) window.location = redirect_url
          });

        }
        else {
          console.log('Cannot access');
        }
      }).catch(error => {
        this.setState({ loading: false });
      })

    })
  }

  showWithdraw(show = true) {
    this.setState({ showWithdraw: show })
  }

  switchMode(mode) {
    if (this.props.switchMode) this.props.switchMode(mode);
  }


  render() {
    const { loading, loadingUFABET, logined, credit, expire, showWithdraw, withdrawMin, allowWithdraw, creditAllow } = this.state;

    const { promotion, depositx2, isExpire } = this.state;

    return (
      <>


        {(logined) ? (
          <>
            <Row className="justify-content-md-center ">
              <Col sm={12} lg={6} xs={12} >

                <Card className="my-4">
                  <Card.Body>

                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                    ) : (
                      <>
                        <p>โปรโมชั่นรับเครดิตเพิ่ม x 2</p>
                        <Row>
                          <Col>
                            <b><h4 className="text-success">เครดิตปัจจุบัน </h4> </b>

                            {(credit === -999) ? (
                              <div className="text-danger my-2">ไม่สามารถแสดงรายการได้ขณะนี้</div>
                            ) : (
                              <h2 className="my-2">{credit} บาท</h2>
                            )}

                            <h4 className="text-info">จะหมดอายุ {expire}</h4>

                            <h5>ยอดถอนขั้นต่ำ {withdrawMin} บาท</h5>
                            <h5>ยอดเงินที่จะได้รับ {creditAllow} บาท</h5>

                          </Col>
                        </Row>

                      </>
                    )}

                  </Card.Body>
                </Card>

              </Col>
            </Row>



            <Row className="justify-content-md-center ">

              <Col sm={12} lg={6} xs={12} >

                <Row className="mb-4">
                  {(depositx2 && !depositx2.locked && !isExpire) &&
                    <Col sm={12} lg={12} xs={12} className="my-2">
                      <Button variant="primary" size="lg" className="button-flex btn-promotion" block onClick={this.enterGameSite}>
                        {loadingUFABET && <FontAwesomeIcon icon={faSpinner} spin />}  เข้าเล่น UFABET เพิ่ม x 2
                      </Button>
                    </Col>
                  }

                  <Col sm={12} lg={12} xs={12} className="my-2">
                    <Button variant="primary" size="lg" className="p-3 btn-primary" block onClick={() => this.switchMode('normal')}>
                      หน้ารายการปกติ
                    </Button>
                  </Col>

                  {(withdrawMin > 0 && allowWithdraw && depositx2 && depositx2.use && !depositx2.locked) &&
                    <Col sm={12} lg={12} xs={12} className="my-2">
                      <Button variant="primary" size="lg" className="p-3 btn-warning" block onClick={this.showWithdraw}>
                        ถอนเงินโปรโมชั่น x2
                      </Button>
                    </Col>
                  }

                </Row>
              </Col>

            </Row>

            {showWithdraw && <WithdrawComponent promotion={promotion} creditRaw={creditAllow} credit={credit} withdrawMin={withdrawMin} onFinish={() => this.showWithdraw(false)} />}

          </>
        ) : (<Redirect to="/signin" />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Depositx2);