import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

import Layout from '../../component/layout/member';


import GameLuckyWheel from './lucky_wheel'

const cookies = new Cookies();

class GameApp extends React.Component {
  constructor(props) {
    super(props)

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      data,
      game_type: null,
      game: null,
      totalPlay: 0,
      play: false,
      goHome: false,
      goPage: null,
      type: null,
    }

    this.playGame = this.playGame.bind(this);
    this.closeGame = this.closeGame.bind(this);
  }


  componentDidMount() {

    if (this.props.match.params.type && !this.state.game_type) {

      let data = { game_type: this.props.match.params.type, play: true, type: null }
      if (this.props.match.params.page) data.goPage = this.props.match.params.page;

      if (this.props.location.search) {
        let type = new URLSearchParams(this.props.location.search).get("type")
        if (type) data.type = type
      }

      this.setState(data)

    }
    else {

    }
  }

  playGame() {
    this.setState({ play: true })
  }


  closeGame() {
    this.setState({ goHome: true })
  }


  render() {

    const { logined, game, game_type, play, goHome, goPage, type } = this.state

    let path = '/'
    if (goPage) path += goPage

    return (
      <>
        <Layout>
          {logined ? (
            <>
              {(play && game_type === 'lucky_wheel') && <GameLuckyWheel type={type} info={game} onHide={this.closeGame} />}

              {goHome && <Redirect to={path} />}
            </>
          ) : (<Redirect to="/signin" />)}
        </Layout>
      </>
    )
  }
}



const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(GameApp);