import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import '../../../assets/css/main.css'

import request from '../../../libs/request';

const cookies = new Cookies();

class Depositx2Withdraw extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get(process.env.REACT_APP_COOKIE_MEMBER);

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      showModal: false,
      intro: null,
      data: {},
      credit: '',
      creditRaw: 0,
      withdrawMin: 0,
      withdraw_credit: 0,
      promotion: null,
    }

    this.confirm = this.confirm.bind(this)
    this.hideModal = this.hideModal.bind(this);
    this.withdrawValue = this.withdrawValue.bind(this);
  }

  componentDidMount() {

    if (this.props.promotion && this.props.withdrawMin && this.props.withdrawMin > 0 && this.props.creditRaw && this.props.creditRaw > 0) {

      this.setState({
        logined: true,
        showModal: true,
        withdrawMin: this.props.withdrawMin,
        credit: this.props.credit,
        creditRaw: this.props.creditRaw,
        withdraw_credit: this.props.creditRaw,//this.props.withdrawMin,
        promotion: this.props.promotion,
      });

    }

  }


  hideModal(reload = false) {
    this.setState({ showModal: false }, () => {

      if (this.props.onFinish) {
        this.props.onFinish(reload);
      }

    });
  }

  confirm() {
    let credit = this.state.withdrawMin// this.state.withdraw_credit;
    if (credit > 0 && credit >= this.state.withdrawMin) {


      this.setState({ loading: true }, async () => {
        let data = {
          promotion: this.state.promotion.id,
          credit,
        }

        request.post('promotion/depositx2/withdraw', data).then(response => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            this.setState({ loading: false }, () => {

              if (result.success) {

                alert('แจ้งถอนเงิน เรียบร้อยแล้ว')

                window.location.reload();
              }
              else {
                alert(result.message);
              }

              this.hideModal(true);
            });

          }
          else {
            console.log('Cannot call');
          }
        })


      })

    }
    else {
      console.log('Cannot call', credit, this.state.creditRaw, this.state.withdrawMin)
    }
  }

  withdrawValue(e) {
    let credit = e.currentTarget.value || 0;
    this.setState({ withdraw_credit: credit });
  }


  render() {
    const { loading, logined, showModal, creditRaw, withdrawMin } = this.state;


    const allow = (withdrawMin > 0)

    console.log('withdrawMin', withdrawMin)

    return (
      <>


        {logined ? (
          <>
            <Modal show={showModal} onHide={this.hideModal}>
              <Modal.Header >
                <Modal.Title>ถอนเงินโปรโมชั่นเพิ่ม x2</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div className="text-center">
                  <p>
                    ระบบจะถอนเครดิตทั้งหมด และสมาชิกจะได้รับเงิน  <strong className="text-success">{creditRaw}</strong> บาท
                  </p>
                </div>


              </Modal.Body>
              <Modal.Footer>
                {loading ? (
                  <div className="text-center"><FontAwesomeIcon icon={faSpinner} spin size="lg" /></div>
                ) : (
                  <>
                    {allow &&
                      <Button variant="primary" type="button" className="px-4 btn-lg btn-block btn-primary" onClick={this.confirm}>ยืนยันการถอนเงิน</Button>
                    }
                    <Button variant="outline-secondary btn-block" type="button" onClick={this.hideModal}>ยกเลิก</Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>

          </>
        ) : (<Redirect to="/signin" />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Depositx2Withdraw);